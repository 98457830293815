// ----------------------------------------------------
// version: 1.8
// cleanup
// ----------------------------------------------------

// ----------------------------------------------------
// page ready check:
// ----------------------------------------------------

// run initialization after page has loaded:
/* var readyStateCheckInterval = setInterval(function() {
    if (document.readyState === "complete") {
        clearInterval(readyStateCheckInterval);

        if (location.hostname === domainNameWebsite || location.hostname === 'www.' + domainNameWebsite || location.hostname === domainNameWebsiteDev) {
            cookieValues = cookieValuesWebsite;
            hiddenInit = hiddenInitWebsite;
            domainName = domainNameWebsite;

            cookiesEuBanner(cookieValues);
        }
    }
}, 10); */

var readyStateCheckInterval = setInterval(function() {
    if (document.readyState === "complete") {
        clearInterval(readyStateCheckInterval);
        cookiesEuBanner(cookieValues);
    }
}, 10);

// ----------------------------------------------------
// variables:
// ----------------------------------------------------

// set cookie values:
// name:                cookie name (has to match toggle button ids)
// value:               initial value
// fixed:               can be changed with checkboxes
// code:                execute this code if the user consented
// deleteCookieNames:   delete these external cookies if the user did not consent
var cookieValues = [
    {
        name: "cookies-eu-necessary",
        value: true,
        fixed: true,
        code: function() {
            //console.log("necessary code executed");
        
            googleMapsWebsite();
        },
        deleteCookieNames: []
    },
    {
        name: "cookies-eu-statistics",
        value: false,
        fixed: false,
        code: function() {
            console.log("statistics code executed");
            
            googleAnalyticsWebsite();
        },
        deleteCookieNames: ["_ga", "_gid", "_gat_gtag_UA_32770973_5"]
    },
    // falls wir mal weitere Kategorien brauchen
    /*{
        name: "cookies-eu-comfort",
        value: false,
        fixed: false,
        code: function() {
            //console.log("comfort code executed");
        },
        deleteCookieNames: []
    },
    {
        name: "cookies-eu-personalisation",
        value: false,
        fixed: false,
        code: function() {
            //console.log("personalisation code executed");
        },
        deleteCookieNames: []
    }*/
];

// hidden initially on these pages, example: "/de/legal/datenschutz":
var hiddenInit = [
    "/footer/datenschutz",
    "/footer/impressum"
];

// deal with www.domain.com and domain.com cookie mix up:
// var domainName = document.domain.replace("www.", "");
// analytics sets cookies on highest domainname, easier to just set the domainname directly:

var domainName = '.step2work.ch';
//var domainNameWebsiteDev = 'step2work.a4interactive.ch';

// ----------------------------------------------------
// functions for individual cookies:
// ----------------------------------------------------

// Google Maps:
function googleMapsWebsite() {
    // initMap() and loadMap() in view file
    
    // get map element
    var map = document.getElementById('map');
    
    if (map) {
        // script exists
        if (typeof google !== undefined && typeof google === 'object' && typeof google.maps === 'object') {
            initMap();

        // load script
        } else {
            loadMap();
        }
    }
}

// Google Analytics:
function googleAnalyticsWebsite() {
    var script = document.createElement('script');
  
    script.src = "https://www.googletagmanager.com/gtag/js?id=UA-32770973-5";
	
	script.onload = function () {
	    window.dataLayer = window.dataLayer || [];
	    function gtag() {
            dataLayer.push(arguments);
		}
	    gtag('js', new Date());
	    gtag('config', 'UA-32770973-5', {
            'anonymize_ip': true,
            'cookie_domain': 'step2work.ch'
        });
	};
	
	document.head.appendChild(script);
}

// Google Analytics:
/*
function googleAnalyticsShop() {
    var script = document.createElement('script');
  
    script.src = "https://www.googletagmanager.com/gtag/js?id=UA-32770973-4";
	
	script.onload = function () {
	    window.dataLayer = window.dataLayer || [];
	    function gtag() {
            dataLayer.push(arguments);
		}
	    gtag('js', new Date());
	    gtag('config', 'UA-32770973-4', {
            'anonymize_ip': true,
            'cookie_domain': 'shop.bsz-stiftung.ch'
        });
	};
	
	document.head.appendChild(script);
}
*/
// ----------------------------------------------------
// main function:
// ----------------------------------------------------
	
function cookiesEuBanner(cookieValues) {
		
    // get banner:
    var banner = document.getElementById("cookies-eu-banner");
    
    // only run if banner is available on the page:
    if (banner) {
    
        // get save and revoke buttons
        var saveButton = document.getElementById("cookies-eu-save");
        var saveAllButton = document.getElementById("cookies-eu-save-all");
        var saveMinimumButton = document.getElementById("cookies-eu-save-minimum");
        var revokeButton = document.getElementById("cookies-eu-revoke");
        var revokeButtonMobile = document.getElementById("cookies-eu-revoke-mobile");

        // destroy banner element for DoNotTrack and bots and exit main code:
        if (botCheck(banner)) {
            return false;
        }
        
        // allow interaction with checkmark buttons for not fixed cookies
        cookieValues.forEach(function(e) {
            if (e.fixed === false) {
                toggleClassListener(document.getElementById(e.name), banner);
            } else {
                document.getElementById(e.name).setAttribute("disabled", "");
            }
        })
        
        // enable click listeners for all buttons
        saveListener(saveButton, banner);
        saveAllListener(saveAllButton, banner);
        saveMinimumListener(saveMinimumButton, banner);
        revokeListener(revokeButton, banner);
        revokeListener(revokeButtonMobile, banner);
        
        // only show banner if cookies dont exist:
        if (hasConsented(cookieValues) === true) {
            hideBanner(banner);
        } else {
            
            // hide banner for exception sites:
            var hide = false;
            
            hiddenInit.forEach(function(e) {
                if (window.location.pathname === e) {
                    hide = true;
                }
            })
            
            if (hide) {
                hideBanner(banner);
            } else {
                showBanner(banner);
            }
        }
    }
}

// ----------------------------------------------------
// sub functions:
// ----------------------------------------------------

// -------------------
// remove banner from website for doNotTrack browser setting and crawlers
// -------------------

function botCheck(banner) {
    var bots = /bot|crawler|spider|crawling/i;
    var isBot = bots.test(navigator.userAgent);
    var doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack || window.doNotTrack;
    var isToTrack = (doNotTrack !== null && doNotTrack !== undefined) ? (doNotTrack && doNotTrack !== 'yes' && doNotTrack !== 1 && doNotTrack !== '1') : true;
    
    if (isBot || !isToTrack) {
        removeBanner(banner);
        return true;
    }
}

// -------------------
// iterate over checkmark buttons, toggle checkmark, enable/disable cookie code
// -------------------

function toggleClassListener(button, banner) {
    if (button && banner) {
        button.addEventListener("click", function() {
        if (button.classList.contains("consented")) {
            button.classList.remove("consented");
            
            cookieValues.forEach(function(e) {
                if (e.name === button.id) {
                    e.value = false;
                }
            })
        } else {
            button.classList.add("consented");
            
            cookieValues.forEach(function(e) {
                if (e.name === button.id) {
                    e.value = true;
                }
            })
        }
        })
    }
}

// -------------------
// consent to selected cookies and hide banner
// -------------------

function saveListener(button, banner) {
    if (button && banner) {
        button.addEventListener("click", function() {
            save('selected');
            hideBanner(banner);
        })
    }
}

// -------------------
// consent to selected cookies and hide banner
// -------------------

function saveMinimumListener(button, banner) {
    if (button && banner) {
        button.addEventListener("click", function() {
            save('minimum');
            hideBanner(banner);
        })
    }
}

// -------------------
// consent to all cookies and hide banner
// -------------------

function saveAllListener(button, banner) {
    if (button && banner) {
        button.addEventListener("click", function() {
            save('all');
            hideBanner(banner);
        })
    }
}

// -------------------
// show banner if cookie settings button is clicked
// -------------------

function revokeListener(button, banner) {
    if (button && banner) {
        button.addEventListener("click", function() {
            showBanner(banner);
        })
    }
}

// -------------------
// send cookie consent to webserver
// -------------------

function sendConsentToServer() {
  
    // url has to match cookie controller
    var url = '/cookie-consent';

    // build query from cookie values
    var query = '?';

    for (var i = 0; i < cookieValues.length; i++) {
        query += cookieValues[i].name + '=' + getLocalStorage(cookieValues[i].name);

        if (i !== cookieValues.length - 1) {
            query += '&';
        }
    }

    // call consent controller, returns json
    fetch(url + query)
    .then(function(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    }).then(function(response) {
        //console.log(response);
    }).catch(function(error) {
        //console.log(error);
    });

}

// -------------------
// save cookies
// -------------------

// cookies == all
// cookies == selected
// cookies == minimum (fallback)

function save(cookies) {
    for (var i = 0; i < cookieValues.length; i++) {

        // save all
        if (cookies === 'all') {
            setLocalStorage(cookieValues[i].name, "true");
            document.getElementById(cookieValues[i].name).classList.add("consented");

            cookieValues[i].code();
        
        // save selected
        } else if (cookies === 'selected') {
            setLocalStorage(cookieValues[i].name, cookieValues[i].value);
            
            if (getLocalStorage(cookieValues[i].name) === "true" || getLocalStorage(cookieValues[i].name) === true) {
                cookieValues[i].code();
            } else {
                cookieValues[i].deleteCookieNames.forEach(function(e) {
                    deleteCookie(e);
                })
            }
            
        // save minimum
        } else if (cookies === 'minimum') {
            if (cookieValues[i].fixed) {
                cookieValues[i].code();
            } else {
                setLocalStorage(cookieValues[i].name, false);
                
                cookieValues[i].deleteCookieNames.forEach(function(e) {
                    deleteCookie(e);
                })
            }
        }
    }
}

// -------------------
// check if user selected a banner save option once
// -------------------

function hasConsented(cookieValues) {
    var atLeastOneCookie = false;
    
    cookieValues.forEach(function(e) {
        if (getLocalStorage(e.name) === "true") {
            atLeastOneCookie = true;
            e.code();
        } else if (getLocalStorage(e.name) === "false") {
            atLeastOneCookie = true;
        }
    })
    
    if (atLeastOneCookie === true) {
        return true;
    } else {
        return false
    }
}

// -------------------
// hide banner
// -------------------

function hideBanner(banner) {
    if (banner) {
        banner.classList.remove("is-active");
        //body.classList.remove("no-scrollbar");
        document.querySelector("body").style = "overflow-y: auto";
    }
}

// -------------------
// show banner
// -------------------

function showBanner(banner) {
    if (banner) {
        cookieValues.forEach(function (e) {
            if (getLocalStorage(e.name) === true || getLocalStorage(e.name) === "true") {
                e.value = true;
            } else if (getLocalStorage(e.name) === false || getLocalStorage(e.name) === "false") {
                e.value = false;
            }
        })
        
        applyClasses(cookieValues);
        
        banner.classList.add("is-active");
        //body.classList.add("no-scrollbar");
        //document.querySelector("body").classList.add("no-scrollbar");
        document.querySelector("body").style = "overflow-y: hidden";
    }
}

// -------------------
// remove banner from website
// -------------------

function removeBanner(banner) {
    if (banner && banner.parentNode) {
        banner.parentNode.removeChild(banner);
    }
}

// -------------------
// add or remove checkmark for selected cookies on banner
// -------------------

function applyClasses(cookieValues) {
	cookieValues.forEach(function(e) {
        if (e.value === true) {
            document.getElementById(e.name).classList.add("consented");
        } else {
            document.getElementById(e.name).classList.remove("consented");
        }
	})
}

// ----------------------------------------------------
// Cookies
// ----------------------------------------------------

// -------------------
// delete cookie data from browser (check first if cookie exists to prevent creating empty cookie)
// -------------------

function deleteCookie(name) {
    if (document.cookie.indexOf(name) >= 0) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; path=/; domain=' + domainName;
    }
}

// ----------------------------------------------------
// localStorage
// ----------------------------------------------------

// -------------------
// write localStorage data to browser
// -------------------

function setLocalStorage(name, value) {
	localStorage.setItem(name, value);
}

// -------------------
// read localStorage data from browser
// -------------------

function getLocalStorage(name) {
	if (localStorage.getItem(name)) {
		return localStorage.getItem(name);
	} else return null;
}