//*******************************
// Shrink desktop navigation on scroll
//*******************************

addScrollClass('.desktop-menu');
addScrollClass('.main-shop');

document.addEventListener("scroll", function() {
  addScrollClass('.desktop-menu');
  addScrollClass('.main-shop');
});

function addScrollClass(element) {
  var target = document.querySelector(element);

  if (target) {
    if (window.pageYOffset > 100) {
      target.classList.add("has-scrolled");
    } else {
      target.classList.remove("has-scrolled");
    }
  }
}

//*******************************
// Toggle password visibility
//*******************************

var toggle = document.getElementById('showPassword');
var element = document.getElementById('_password');

if (toggle && element) {
  toggle.addEventListener('click', function() {
    toggleHiddenPassword(element);
  });
}

function toggleHiddenPassword(element) {
  if (element.type === 'password') {
    element.type = 'text';
  } else {
    element.type = 'password';
  }
}

//*******************************
// Toggle mobile folder overlay
//*******************************

var mobileFilterButton = document.getElementById('mobileFilterButton');
var mobileFilterButtonClose = document.getElementById('mobileFilterButtonClose');
var mobileFilterOverlay = document.getElementById('mobileFilterOverlay');

if (mobileFilterButton && mobileFilterOverlay) {
  mobileFilterButton.addEventListener('click', function() {
    mobileFilterOverlay.classList.toggle('hidden');
  });
}

if (mobileFilterButtonClose && mobileFilterOverlay) {
  mobileFilterButtonClose.addEventListener('click', function() {
    mobileFilterOverlay.classList.toggle('hidden');
  });
}

//*******************************
// Toggle navigation dropdowns
//*******************************

/*
var searchButton = document.getElementById('searchButton');
var searchInput = document.getElementById('searchInput');

if (searchButton && searchInput) {
  searchButton.addEventListener('click', function(e) {
    e.preventDefault();
    searchInput.classList.add('active');
  });
}
*/

//var accountToggler = document.getElementById('accountToggler');
//var accountDropdown = document.getElementById('accountDropdown');

/*
accountToggler.addEventListener('click', function(e) {
  e.preventDefault();
  accountDropdown.classList.add('is-open');
  
  console.log("click")

  //accountDropdown.foundation('toggle');
  //$('#accountDropdown').foundation('toggle');
})
*/

//*******************************
// change quantity in product detail
//*******************************

changeQuantityDetail();

function changeQuantityDetail() {
  var btnIncrease = document.getElementById("btn-increase");
  var btnDecrease = document.getElementById("btn-decrease");
  var quantity = document.getElementById("quantity");

  if (btnIncrease && btnDecrease && quantity) {
    btnIncrease.addEventListener("click", function(e) {
      e.preventDefault();

      if (quantity.value < 99) {
        quantity.value = parseInt(quantity.value, 10) + 1;
      }
    })

    btnDecrease.addEventListener("click", function(e) {
      e.preventDefault();

      if (quantity.value > 1) {
        quantity.value = parseInt(quantity.value, 10) - 1;
      }
    })
  }
}

//*******************************
// checkout billing address is not delivery address
//*******************************

var billingIsNotDelivery = document.getElementById("delivery_address_form_billingisnotdelivery");
var checkoutDeliveryAddress = document.getElementById('checkout-delivery-address');

toggleClassByCheckboxDelivery(billingIsNotDelivery, checkoutDeliveryAddress, 'hidden');

// toggle class with checkbox, adds required
function toggleClassByCheckboxDelivery(checkbox, target, className) {
  var pickupLocation = document.getElementById("delivery_address_form_pickupLocation");
  var pickupDate = document.getElementById("delivery_address_form_pickupDate");

  var firstnamedelivery = document.getElementById("delivery_address_form_firstnamedelivery");
  var lastnamedelivery = document.getElementById("delivery_address_form_lastnamedelivery");
  var streetdelivery = document.getElementById("delivery_address_form_streetdelivery");
  var zipdelivery = document.getElementById("delivery_address_form_zipdelivery");
  var citydelivery = document.getElementById("delivery_address_form_citydelivery");

  var genderdelivery0 = document.getElementById("delivery_address_form_genderdelivery_0");
  var genderdelivery1 = document.getElementById("delivery_address_form_genderdelivery_1");

  if (checkbox && target && firstnamedelivery && lastnamedelivery && streetdelivery && zipdelivery && citydelivery && genderdelivery0 && genderdelivery1) {
    checkbox.addEventListener('change', function() {
      if (this.checked) {
        firstnamedelivery.required = true;
        lastnamedelivery.required = true;
        streetdelivery.required = true;
        zipdelivery.required = true;
        citydelivery.required = true;
        genderdelivery0.required = true;
        genderdelivery1.required = true;

        target.classList.remove(className);

        // disable pickup
        pickupToggle.checked = false;
        pickupData.classList.add('hidden');

        pickupLocation.required = false;
        pickupDate.required = false;
      } else {
        firstnamedelivery.required = false;
        lastnamedelivery.required = false;
        streetdelivery.required = false;
        zipdelivery.required = false;
        citydelivery.required = false;
        genderdelivery0.required = false;
        genderdelivery1.required = false;

        target.classList.add(className);
      }
    })
  }
}

// show delivery address initially if selected
$(document).ready(function() {
  if (billingIsNotDelivery && billingIsNotDelivery.checked && checkoutDeliveryAddress) {
    checkoutDeliveryAddress.classList.remove('hidden');
  }
});

//*******************************
// checkout pickup
//*******************************

var pickupToggle = document.getElementById('delivery_address_form_pickup');
var pickupData = document.getElementById('pickupData');

toggleClassByCheckboxPickup(pickupToggle, pickupData, 'hidden');

function toggleClassByCheckboxPickup(checkbox, target, className) {
  var pickupLocation = document.getElementById("delivery_address_form_pickupLocation");
  var pickupDate = document.getElementById("delivery_address_form_pickupDate");

  var firstnamedelivery = document.getElementById("delivery_address_form_firstnamedelivery");
  var lastnamedelivery = document.getElementById("delivery_address_form_lastnamedelivery");
  var streetdelivery = document.getElementById("delivery_address_form_streetdelivery");
  var zipdelivery = document.getElementById("delivery_address_form_zipdelivery");
  var citydelivery = document.getElementById("delivery_address_form_citydelivery");

  if (pickupLocation && pickupDate ) {
    checkbox.addEventListener('change', function() {
      if (this.checked) {
        pickupLocation.required = true;
        pickupDate.required = true;

        target.classList.remove(className);

        // disable delivery
        billingIsNotDelivery.checked = false;
        checkoutDeliveryAddress.classList.add('hidden');

        firstnamedelivery.required = false;
        lastnamedelivery.required = false;
        streetdelivery.required = false;
        zipdelivery.required = false;
        citydelivery.required = false;
      } else {
        pickupLocation.required = false;
        pickupDate.required = false;

        target.classList.add(className);
      }
    })
  }
}

// show pickupData initially if selected
$(document).ready(function() {
  if (pickupToggle && pickupToggle.checked && pickupData) {
    pickupData.classList.remove('hidden');
  }
});

//*******************************
// toggle register form
//*******************************

var registerToggle = document.getElementById('register-toggle');
var registerForm = document.getElementById('register-form');
var registerArrow = document.getElementById('register-arrow');

toggleClassByButton(registerToggle, [registerForm, registerArrow], 'hidden');

//*******************************
// show complete register form on input
//*******************************

/*var registrationFormEmail = document.getElementById("registration_form_email");
var registrationFormPasswordFirst = document.getElementById("registration_form_password_first");
var registrationFormPasswordSecond = document.getElementById("registration_form_password_second");
var showRegisterOnInput = document.getElementById('show-register-on-input');

addClassOnInput([registrationFormEmail, registrationFormPasswordFirst, registrationFormPasswordSecond], showRegisterOnInput, 'hidden');

// show form after failed register attempt
if (showRegisterOnInput && registrationFormEmail && registrationFormEmail.value) {
  showRegisterOnInput.classList.remove("hidden");
}*/

//*******************************
// toggle forgot password
//*******************************

var accountLogin = document.getElementById('account-login');
var showForgotPassword = document.getElementById('show-forgot-password');

var recoverPassword = document.getElementById('recover-password');
var forgotPasswordBack = document.getElementById('forgot-password-back');

toggleClassByButton(showForgotPassword, [accountLogin, recoverPassword], 'hidden');
toggleClassByButton(forgotPasswordBack, [accountLogin, recoverPassword], 'hidden');

//*******************************
// change password
//*******************************

// buttons
var passwordButton = document.getElementById("password-button");

// blocks
var changePassword = document.getElementById("change-password");
var changePasswordStatic = document.getElementById("change-password-static");

var targets = [
  changePassword, 
  changePasswordStatic, 
];

toggleClassByButton(passwordButton, targets, 'hidden');

//*******************************
// save account details
//*******************************

// buttons
var billingAddressButton = document.getElementById("billing-address-button");
var deliveryAddressButton = document.getElementById("delivery-address-button");
var mailAddressButton = document.getElementById("mail-address-button");

// blocks
var billingAddress = document.getElementById("billing-address");
var billingAddressStatic = document.getElementById("billing-address-static");
var deliveryAddress = document.getElementById("delivery-address");
var deliveryAddressStatic = document.getElementById("delivery-address-static");
var mailAddress = document.getElementById("mail-address");
var mailAddressStatic = document.getElementById("mail-address-static");
var submitAddress = document.getElementById("submit-address");

var targets = [
  billingAddress, 
  billingAddressStatic, 
  deliveryAddress, 
  deliveryAddressStatic, 
  mailAddress, 
  mailAddressStatic,
  submitAddress
];

toggleClassByButton(billingAddressButton, targets, 'hidden');
toggleClassByButton(deliveryAddressButton, targets, 'hidden');
toggleClassByButton(mailAddressButton, targets, 'hidden');

//*******************************
// toggle product filters list
//*******************************

var mobilefilter = document.getElementById("mobile-filter");
var filterButtons = document.getElementById("filter-buttons");

toggleClassByButton(mobilefilter, targets = [filterButtons], "hidden");
toggleClassByButton(mobilefilter, targets = [mobilefilter], "open");

//*******************************
// parallax
//*******************************

window.addEventListener('scroll', function() {
  var elements = document.querySelectorAll(".parallax");

  for (var i = 0; i < elements.length; i++) {
    parallax(elements[i]);
  }
});

//*******************************
// disable AOS in product detail on mobile
//*******************************

/*
var aosDisableFadeUp = document.querySelectorAll(".aos-disable-fade-up");
var aosDisableFadeIn = document.querySelectorAll(".aos-disable-fade-in");

disableAOS(aosDisableFadeUp, "fade-up", 640);
disableAOS(aosDisableFadeIn, "fade-in", 640);

window.addEventListener("resize", function() {
  disableAOS(aosDisableFadeUp, "fade-up", 640);
  disableAOS(aosDisableFadeIn, "fade-in", 640);
});
*/

//*******************************
// check if javascript is disabled on forms
//*******************************

$(document).ready(function() {
  jsfilled("help_form_filled");
  jsfilled("registration_form_filled");
});

//*******************************
// functions
//*******************************

// check if javascript is disabled on forms
function jsfilled(element) {
  var f = document.getElementById(element);
  
  if (f) {
    f.value = "jsfilled";
  }
}

// disable AOS animations below medium width
/*
function disableAOS(targets = [], animation, width) {
  if (window.innerWidth < width) {
    for (var i = 0; i < targets.length; i++) {
      targets[i].setAttribute("data-aos", "")
    }
  } else {
    for (var i = 0; i < targets.length; i++) {
      targets[i].setAttribute("data-aos", animation);
    }
  }
}
*/

// toggle class on targets array with button
function toggleClassByButton(button, targets = [], className) {
  if (button) {
    button.addEventListener('click', function(e) {
      e.preventDefault();

      for (var i = 0; i < targets.length; i++) {
        if (targets[i]) {
          targets[i].classList.toggle(className);
        }
      }
    })
  }
}

// toggle class with checkbox
function toggleClassByCheckbox(checkbox, target, className) {
  if (checkbox && target) {
    checkbox.addEventListener('change', function() {
      if (this.checked) {
        target.classList.remove(className);
      } else {
        target.classList.add(className);
      }
    })
  }
}

// add class on input
function addClassOnInput(inputs = [], target, className) {
  if (target) {
    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i]) {
        inputs[i].addEventListener("input", function() {
          if (target.classList.contains(className)) {
            target.classList.remove(className);
          }
        })
      }
    }
  }
}

// parallax
function parallax(element) {
  // .parallax {
  //   height: 525px;
  //   background-position: center;
  // }

  if (element) {
    var rect = element.getBoundingClientRect();

    if (rect.top <= window.innerHeight && rect.bottom >= 0) {
      // image leaves screen scrolling down: rect.bottom == 0
      // image shows on screen scrolling down: window.innerHeight - rect-top == 0

      // backgroundPositionY: 0% ... 100%
      // bug: goes from around ~ -30% to 100%
      element.style.backgroundPositionY = (100 / window.innerHeight * rect.top) + '%';
    } else {
      element.style.backgroundPositionY = '50%';
    }
  }
}