//*******************************
// Recover password form validation
//*******************************

sendShopRecoverPasswordForm();

function sendShopRecoverPasswordForm() {
  var form = document.getElementById('recoverPassword');
  var invalid = document.getElementById('recoverPasswordFormInvalid');

  if (form && invalid) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();

      if (validateShopRecoverPasswordForm()) {
        invalid.classList.add('hide');
        form.submit();
      } else {
        invalid.classList.remove('hide');
        shopRecoverPasswordFormSendOnce = true;
      }
    })
  }
}

function validateShopRecoverPasswordForm() {
  var form = document.getElementById('recoverPassword');

  // email
  var email = form['resetemail'];

  var valid = true;

  // email input
  var emailRegex = /^.+@.+\..+$/;

  if (!(email.value && email.value.match(emailRegex))) {
    valid = false;
    email.classList.add('invalid');
  } else {
    email.classList.remove('invalid');
  }

  return valid;
}

//*******************************
// Login form validation
//*******************************

sendShopLoginForm();

function sendShopLoginForm() {
  var form = document.getElementById('login_form');
  var invalid = document.getElementById('loginFormInvalid');

  if (form && invalid) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();

      if (validateShopLoginForm()) {
        invalid.classList.add('hide');
        form.submit();
      } else {
        invalid.classList.remove('hide');
        shopLoginFormSendOnce = true;
      }
    })
  }
}

function validateShopLoginForm() {
  var form = document.getElementById('login_form');

  // email
  var email = form['_username'];

  // text
  var requiredText = [
    form['_password']
  ];

  var valid = true;

  // text input
  for (var i = 0; i < requiredText.length; i++) {
    if (!requiredText[i].value) {
      valid = false;
      requiredText[i].classList.add('invalid');
    } else {
      requiredText[i].classList.remove('invalid');
    }
  }

  // email input
  var emailRegex = /^.+@.+\..+$/;

  if (!(email.value && email.value.match(emailRegex))) {
    valid = false;
    email.classList.add('invalid');
  } else {
    email.classList.remove('invalid');
  }

  return valid;
}

//*******************************
// Help form validation
//*******************************

sendShopHelpForm();

function sendShopHelpForm() {
  var form = document.forms['help_form'];
  var invalid = document.getElementById('helpFormInvalid');

  if (form && invalid) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();

      if (validateShopHelpForm()) {
        invalid.classList.add('hide');
        form.submit();
      } else {
        invalid.classList.remove('hide');
        shopHelpFormSendOnce = true;
      }
    })
  }
}

function validateShopHelpForm() {
  var form = document.forms['help_form'];

  // email
  var email = form['help_form[email]'];

  // text
  var requiredText = [
    form['help_form[name]'],
    form['help_form[message]']
  ];

  var valid = true;

  // text input
  for (var i = 0; i < requiredText.length; i++) {
    if (!requiredText[i].value) {
      valid = false;
      requiredText[i].classList.add('invalid');
    } else {
      requiredText[i].classList.remove('invalid');
    }
  }

  // email input
  var emailRegex = /^.+@.+\..+$/;

  if (!(email.value && email.value.match(emailRegex))) {
    valid = false;
    email.classList.add('invalid');
  } else {
    email.classList.remove('invalid');
  }

  return valid;
}

//*******************************
// Password form validation
//*******************************

sendShopPasswordForm();

function sendShopPasswordForm() {
  var form = document.forms['change_password_form'];
  var invalid = document.getElementById('passwordFormInvalid');

  if (form && invalid) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();

      if (validateShopPasswordForm()) {
        invalid.classList.add('hide');
        form.submit();
      } else {
        invalid.classList.remove('hide');
        shopHelpFormSendOnce = true;
      }
    })
  }
}

function validateShopPasswordForm() {
  var form = document.forms['change_password_form'];

  // old password
  var oldPassword = form['change_password_form[old_password]'];

  // new passwords
  var passwordFirst = form['change_password_form[new_password][first]'];
  var passwordSecond = form['change_password_form[new_password][second]'];

  var valid = true;

  // old password
  if (!oldPassword.value) {
    valid = false;
    oldPassword.classList.add('invalid');
  } else {
    oldPassword.classList.remove('invalid');
  }

  // new passwords
  if (!(passwordFirst.value && passwordSecond.value && passwordFirst.value === passwordSecond.value)) {
    valid = false;
    passwordFirst.classList.add('invalid');
    passwordSecond.classList.add('invalid');
  } else {
    passwordFirst.classList.remove('invalid');
    passwordSecond.classList.remove('invalid');
  }

  return valid;
}

//*******************************
// Registration form validation
//*******************************

sendShopRegistrationForm();

function sendShopRegistrationForm() {
  var form = document.forms['registration_form'];
  var registrationFormInvalid = document.getElementById('registrationFormInvalid');

  if (form && registrationFormInvalid) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();

      if (validateShopRegistrationForm()) {
        registrationFormInvalid.classList.add('hide');
        form.submit();
      } else {
        registrationFormInvalid.classList.remove('hide');
        shopRegistrationFormSendOnce = true;
      }
    })
  }
}

function validateShopRegistrationForm() {
  var form = document.forms['registration_form'];

  // radio
  var genderContainer = document.getElementsByClassName('gender')[0];
  var genderAll = form['registration_form[gender]'];

  // email
  var email = form['registration_form[email]'];

  // password
  var passwordFirst = form['registration_form[password][first]'];
  var passwordSecond = form['registration_form[password][second]'];

  // checkbox
  var agreementContainer = document.getElementsByClassName('agreementContainer')[0];
  var agreement = form['registration_form[agreement]'];

  // text
  var requiredText = [
    form['registration_form[firstname]'],
    form['registration_form[lastname]'],
    form['registration_form[street]'],
    form['registration_form[city]'],
    form['registration_form[phone]']
  ];

  // number
  var requiredNumber = [
    form['registration_form[zip]']
  ];

  var valid = true;

  // radio
  if (!genderAll.value) {
    valid = false;
    genderContainer.classList.add('invalid');
  } else {
    genderContainer.classList.remove('invalid');
  }

  // text input
  for (var i = 0; i < requiredText.length; i++) {
    if (!requiredText[i].value) {
      valid = false;
      requiredText[i].classList.add('invalid');
    } else {
      requiredText[i].classList.remove('invalid');
    }
  }

  // email input
  var emailRegex = /^.+@.+\..+$/;

  if (!(email.value && email.value.match(emailRegex))) {
    valid = false;
    email.classList.add('invalid');
  } else {
    email.classList.remove('invalid');
  }

  // password input
  if (!(passwordFirst.value && passwordSecond.value && passwordFirst.value === passwordSecond.value)) {
    valid = false;
    passwordFirst.classList.add('invalid');
    passwordSecond.classList.add('invalid');
  } else {
    passwordFirst.classList.remove('invalid');
    passwordSecond.classList.remove('invalid');
  }

  // number input
  for (var i = 0; i < requiredNumber.length; i++) {
    var regex = /^[+]?[0-9]+$/;

    if (!(requiredNumber[i].value && requiredNumber[i].value.match(regex))) {
      valid = false;
      requiredNumber[i].classList.add('invalid');
    } else {
      requiredNumber[i].classList.remove('invalid');
    }
  }

  // checkbox
  if (!agreement.checked) {
    valid = false;
    agreementContainer.classList.add('invalid');
  } else {
    agreementContainer.classList.remove('invalid');
  }

  return valid;
}

//*******************************
// Delivery address form validation
//*******************************

sendShopDeliveryAddressForm();

function sendShopDeliveryAddressForm() {
  var form = document.forms['delivery_address_form'];
  var formInvalid = document.getElementById('deliveryAddressFormInvalid');

  if (form && formInvalid) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();

      if (validateShopDeliveryAddressForm()) {
        formInvalid.classList.add('hide');
        form.submit();
      } else {
        formInvalid.classList.remove('hide');
        shopDeliveryAddressFormSendOnce = true;
      }
    })
  }
}

function validateShopDeliveryAddressForm() {
  var form = document.forms['delivery_address_form'];

  // radio
  var genderContainer = document.getElementsByClassName('gender')[0];
  var genderAll = form['delivery_address_form[gender]'];

  var genderContainerDelivery = document.getElementsByClassName('gender')[1];
  var genderAllDelivery = form['delivery_address_form[genderdelivery]'];

  // checkbox
  var agreementContainer = document.getElementsByClassName('agreementContainer')[0];
  var agreement = form['delivery_address_form[agreement]'];

  // email
  var email = form['delivery_address_form[email]'];

  // text
  var requiredText = [
    form['delivery_address_form[firstname]'],
    form['delivery_address_form[lastname]'],
    form['delivery_address_form[street]'],
    form['delivery_address_form[city]'],
    form['delivery_address_form[phone]'],
    form['delivery_address_form[pickupDate]']
  ];

  // number
  var requiredNumber = [
    form['delivery_address_form[zip]']
  ];

  // billing is not delivery
  var billingIsNotDelivery = document.getElementById('delivery_address_form_billingisnotdelivery');

  if (billingIsNotDelivery && billingIsNotDelivery.checked) {
    requiredText = requiredText.concat([
      form['delivery_address_form[firstnamedelivery]'],
      form['delivery_address_form[lastnamedelivery]'],
      form['delivery_address_form[streetdelivery]'],
      form['delivery_address_form[citydelivery]'],
    ]);

    requiredNumber = requiredNumber.concat([
      form['delivery_address_form[zipdelivery]']
    ])
  }

  // pickup location
  var delivery_address_form_pickup = document.getElementById('delivery_address_form_pickup');

  if (delivery_address_form_pickup && delivery_address_form_pickup.checked) {
    requiredText = requiredText.concat([
      form['delivery_address_form[pickupLocation]'],
      //form['delivery_address_form[pickupDate]']
    ]);
  }

  var valid = true;

  // radio
  if (!genderAll.value) {
    valid = false;
    genderContainer.classList.add('invalid');
  } else {
    genderContainer.classList.remove('invalid');
  }

  if (billingIsNotDelivery && billingIsNotDelivery.checked) {
    if (!genderAllDelivery.value) {
      valid = false;
      genderContainerDelivery.classList.add('invalid');
    } else {
      genderContainerDelivery.classList.remove('invalid');
    }
  }

  // text input
  for (var i = 0; i < requiredText.length; i++) {
    if (!requiredText[i].value) {
      valid = false;
      requiredText[i].classList.add('invalid');
    } else {
      requiredText[i].classList.remove('invalid');
    }
  }

  // email input
  var emailRegex = /^.+@.+\..+$/;

  if (!(email.value && email.value.match(emailRegex))) {
    valid = false;
    email.classList.add('invalid');
  } else {
    email.classList.remove('invalid');
  }

  // number input
  for (var i = 0; i < requiredNumber.length; i++) {
    var regex = /^[+]?[0-9]+$/;

    if (!(requiredNumber[i].value && requiredNumber[i].value.match(regex))) {
      valid = false;
      requiredNumber[i].classList.add('invalid');
    } else {
      requiredNumber[i].classList.remove('invalid');
    }
  }

  // checkbox
  if (agreement) {
    if (!agreement.checked) {
      valid = false;
      agreementContainer.classList.add('invalid');
    } else {
      agreementContainer.classList.remove('invalid');
    }
  }

  return valid;
}

//*******************************
// Form validation on input changes
//*******************************

var shopRegistrationForm = document.forms['registration_form'];
var shopHelpForm = document.forms['help_form'];
var shopDeliveryAddressForm = document.forms['delivery_address_form'];
var shopPasswordForm = document.forms['change_password_form'];
var shopLoginForm = document.getElementById('login_form');
var shopRecoverPasswordForm = document.getElementById('recoverPassword');

var shopRegistrationFormSendOnce = false;
var shopHelpFormSendOnce = false;
var shopDeliveryAddressFormSendOnce = false;
var shopHelpFormSendOnce = false;
var shopLoginFormSendOnce = false;
var shopRecoverPasswordFormSendOnce = false;

formShopChanged(shopRegistrationForm, validateShopRegistrationForm);
formShopChanged(shopHelpForm, validateShopHelpForm);
formShopChanged(shopDeliveryAddressForm, validateShopDeliveryAddressForm);
formShopChanged(shopPasswordForm, validateShopPasswordForm);
formShopChanged(shopLoginForm, validateShopLoginForm);
formShopChanged(shopRecoverPasswordForm, validateShopRecoverPasswordForm);

function formShopChanged(form, validate) {
  if (form) {
    for (var i = 0; i < form.elements.length; i++) {
      form.elements[i].addEventListener('change', function(e) {
        if (shopRegistrationFormSendOnce || shopHelpFormSendOnce || shopDeliveryAddressFormSendOnce || shopHelpFormSendOnce || shopLoginFormSendOnce || shopRecoverPasswordFormSendOnce) {
          validate();
        }
      })
    }
  }
}