//*******************************
// Application form validation
//*******************************

sendApplicationForm();

function sendApplicationForm() {
  var form = document.forms['application_form'];
  var applicationFormInvalid = document.getElementById('applicationFormInvalid');

  if (form && applicationFormInvalid) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();

      if (validateApplicationForm()) {
        applicationFormInvalid.classList.add('hide');
        form.submit();
      } else {
        applicationFormInvalid.classList.remove('hide');
        applicationFormSendOnce = true;
      }
    })
  }
}

function validateApplicationForm() {
  var form = document.forms['application_form'];

  // radio
  var radioContainer = document.getElementsByClassName('radio');
  var radio = [
    form['application_form[genderApplicant]'],
    form['application_form[ivPension]'],
    form['application_form[suvaPension]'],
    form['application_form[compensation]']
  ];

  // email
  var email = form['application_form[email]'];
  var emailApplicant = form['application_form[emailApplicant]'];

  // checkbox diagnosis
  var diagnosisContainer = document.getElementsByClassName('diagnosisContainer')[0];
  var psychological = form['application_form[psychologial]'];
  var mentally = form['application_form[mentally]'];
  var physically = form['application_form[physically]'];

  // checkbox living
  var livingContainer = document.getElementsByClassName('livingContainer')[0];
  var living = form['application_form[living]'];
  var daytime = form['application_form[daytime]'];
  var livingDate = form['application_form[livingDate]'];
  var daytimeDate = form['application_form[daytimeDate]'];

  // text
  var requiredText = [
    form['application_form[firstname]'],
    form['application_form[lastname]'],
    form['application_form[street]'],
    form['application_form[city]'],
    form['application_form[phone]'],
    form['application_form[function]'],
    form['application_form[firstnameApplicant]'],
    form['application_form[lastnameApplicant]'],
    form['application_form[streetApplicant]'],
    form['application_form[cityApplicant]'],
    form['application_form[phoneApplicant]'],
    form['application_form[streetLegalApplicant]'],
    form['application_form[cityLegalApplicant]'],
    form['application_form[sv]'],
    form['application_form[birthdate]']
  ];

  // number
  var requiredNumber = [
    form['application_form[zip]'],
    form['application_form[zipApplicant]'],
    form['application_form[zipLegalApplicant]']
  ];

  var valid = true;

  // radio
  for (var i = 0; i < radio.length; i++) {
    if (!radio[i].value) {
      valid = false;
      radioContainer[i].classList.add('invalid');
    } else {
      radioContainer[i].classList.remove('invalid');
    }
  }

  // text input
  for (var i = 0; i < requiredText.length; i++) {
    if (!requiredText[i].value) {
      valid = false;
      requiredText[i].classList.add('invalid');
    } else {
      requiredText[i].classList.remove('invalid');
    }
  }

  // email input
  var emailRegex = /^.+@.+\..+$/;

  if (!(email.value && email.value.match(emailRegex))) {
    valid = false;
    email.classList.add('invalid');
  } else {
    email.classList.remove('invalid');
  }

  if (!(emailApplicant.value && emailApplicant.value.match(emailRegex))) {
    valid = false;
    emailApplicant.classList.add('invalid');
  } else {
    emailApplicant.classList.remove('invalid');
  }

  // number input
  for (var i = 0; i < requiredNumber.length; i++) {
    var regex = /^[+]?[0-9]+$/;

    if (!(requiredNumber[i].value && requiredNumber[i].value.match(regex))) {
      valid = false;
      requiredNumber[i].classList.add('invalid');
    } else {
      requiredNumber[i].classList.remove('invalid');
    }
  }

  // checkbox diagnosis
  if (!psychological.checked && !mentally.checked && !physically.checked) {
    valid = false;
    diagnosisContainer.classList.add('invalid');
  } else {
    diagnosisContainer.classList.remove('invalid');
  }

  // checkbox living or daytime
  if (!living.checked && !daytime.checked) {
    valid = false;
    livingContainer.classList.add('invalid');
  } else {
    livingContainer.classList.remove('invalid');
  }

  if (living.checked && !livingDate.value) {
    valid = false;
    livingDate.classList.add('invalid');
  } else {
    livingDate.classList.remove('invalid');
  }

  if (daytime.checked && !daytimeDate.value) {
    valid = false;
    daytimeDate.classList.add('invalid');
  } else {
    daytimeDate.classList.remove('invalid');
  }

  return valid;
}

//*******************************
// Contact form validation
//*******************************

sendContactForm();

function sendContactForm() {
  var form = document.forms['contact_form'];
  var contactFormInvalid = document.getElementById('contactFormInvalid');

  if (form && contactFormInvalid) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();

      if (validateContactForm()) {
        contactFormInvalid.classList.add('hide');
        form.submit();
      } else {
        contactFormInvalid.classList.remove('hide');
        contactFormSendOnce = true;
      }
    })
  }
}

function validateContactForm() {
  var form = document.forms['contact_form'];

  // radio
  var genderContainer = document.getElementsByClassName('gender')[0];
  var genderAll = form['contact_form[gender]'];

  // email
  var email = form['contact_form[email]'];

  // checkbox
  var agreementContainer = document.getElementsByClassName('agreementContainer')[0];
  var agreement = form['contact_form[agreement]'];

  // text
  var requiredText = [
    form['contact_form[firstname]'],
    form['contact_form[lastname]'],
    //form['contact_form[company]'],
    form['contact_form[street]'],
    form['contact_form[city]'],
    form['contact_form[message]'],
    form['contact_form[phone]']
  ];

  // number
  var requiredNumber = [
    form['contact_form[zip]']
  ];

  var valid = true;

  // radio
  if (!genderAll.value) {
    valid = false;
    genderContainer.classList.add('invalid');
  } else {
    genderContainer.classList.remove('invalid');
  }

  // text input
  for (var i = 0; i < requiredText.length; i++) {
    if (!requiredText[i].value) {
      valid = false;
      requiredText[i].classList.add('invalid');
    } else {
      requiredText[i].classList.remove('invalid');
    }
  }

  // email input
  var emailRegex = /^.+@.+\..+$/;

  if (!(email.value && email.value.match(emailRegex))) {
    valid = false;
    email.classList.add('invalid');
  } else {
    email.classList.remove('invalid');
  }

  // number input
  for (var i = 0; i < requiredNumber.length; i++) {
    var regex = /^[+]?[0-9]+$/;

    if (!(requiredNumber[i].value && requiredNumber[i].value.match(regex))) {
      valid = false;
      requiredNumber[i].classList.add('invalid');
    } else {
      requiredNumber[i].classList.remove('invalid');
    }
  }

  // checkbox
  if (!agreement.checked) {
    valid = false;
    agreementContainer.classList.add('invalid');
  } else {
    agreementContainer.classList.remove('invalid');
  }

  return valid;
}

//*******************************
// Restaurant form validation
//*******************************

sendRestaurantForm();

function sendRestaurantForm() {
  var form = document.forms['restaurant_form'];
  var restaurantFormInvalid = document.getElementById('restaurantFormInvalid');

  if (form) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();

      if (validateRestaurantForm()) {
        restaurantFormInvalid.classList.add('hide');
        form.submit();
      } else {
        restaurantFormInvalid.classList.remove('hide');
        restaurantFormSendOnce = true;
      }
    })
  }
}

function validateRestaurantForm() {
  var form = document.forms['restaurant_form'];

  // email
  var email = form['restaurant_form[email]'];

  // checkbox
  /* var menuContainer = document.getElementsByClassName('menuContainer')[0];
  var menu = form['restaurant_form[menu]']; */

  // text
  var requiredText = [
    form['restaurant_form[firstname]'],
    form['restaurant_form[lastname]'],
    form['restaurant_form[date]'],
    //form['restaurant_form[message]'],
    form['restaurant_form[phone]']
  ];

  // number
  var requiredNumber = [
    form['restaurant_form[people]']
  ];

  var valid = true;

  // text
  for (var i = 0; i < requiredText.length; i++) {
    if (!requiredText[i].value) {
      valid = false;
      requiredText[i].classList.add('invalid');
    } else {
      requiredText[i].classList.remove('invalid');
    }
  }

  // email
  var emailRegex = /^.+@.+\..+$/;

  if (!(email.value && email.value.match(emailRegex))) {
    valid = false;
    email.classList.add('invalid');
  } else {
    email.classList.remove('invalid');
  }

  // number
  for (var i = 0; i < requiredNumber.length; i++) {
    var regex = /^[+]?[0-9]+$/;

    if (!(requiredNumber[i].value && requiredNumber[i].value.match(regex))) {
      valid = false;
      requiredNumber[i].classList.add('invalid');
    } else {
      requiredNumber[i].classList.remove('invalid');
    }
  }

  // menu
  /* if (!menu.checked) {
    valid = false;
    menuContainer.classList.add('invalid');
  } else {
    menuContainer.classList.remove('invalid');
  } */

  return valid;
}

//*******************************
// Job form validation
//*******************************

sendJobForm();

function sendJobForm() {
  var form = document.forms['job_form'];

  if (form) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();

      if (validateJobForm()) {
        jobFormInvalid.classList.add('hide');
        form.submit();
      } else {
        jobFormInvalid.classList.remove('hide');
        jobFormSendOnce = true;
      }
    })
  }
}

function validateJobForm() {
  var form = document.forms['job_form'];

  // radio
  var genderContainer = document.getElementsByClassName('gender')[0];
  var genderAll = form['job_form[gender]'];

  // email
  var email = form['job_form[email]'];

  // files
  var requiredFiles = [
    form['job_form[file1]'],
    //form['job_form[file2]'],
    //form['job_form[file3]'],
    //form['job_form[file4]']
  ];

  // file containers
  var requiredFileContainers = [
    document.getElementsByClassName('custom-file-container-1')[0],
    //document.getElementsByClassName('custom-file-container-2')[0],
    //document.getElementsByClassName('custom-file-container-3')[0],
    //document.getElementsByClassName('custom-file-container-4')[0]
  ];

  // text input
  var requiredText = [
    form['job_form[firstname]'],
    form['job_form[lastname]'],
  ];

  var valid = true;

  // radio
  if (!genderAll.value) {
    valid = false;
    genderContainer.classList.add('invalid');
  } else {
    genderContainer.classList.remove('invalid');
  }

  // text
  for (var i = 0; i < requiredText.length; i++) {
    if (!requiredText[i].value) {
      valid = false;
      requiredText[i].classList.add('invalid');
    } else {
      requiredText[i].classList.remove('invalid');
    }
  }

  // email
  var emailRegex = /^.+@.+\..+$/;

  if (!(email.value && email.value.match(emailRegex))) {
    valid = false;
    email.classList.add('invalid');
  } else {
    email.classList.remove('invalid');
  }

  // files
  for (var i = 0; i < requiredFiles.length; i++) {
    if (!requiredFiles[i].value) {
      valid = false;
      requiredFileContainers[i].classList.add('invalid');
    } else {
      requiredFileContainers[i].classList.remove('invalid');
    }
  }

  return valid;
}

//*******************************
// Guide form validation
//*******************************

sendGuideForm();

function sendGuideForm() {
  var form = document.forms['guide_form'];
  var guideFormInvalid = document.getElementById('guideFormInvalid');

  if (form && guideFormInvalid) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();

      if (validateGuideForm()) {
        guideFormInvalid.classList.add('hide');
        form.submit();
      } else {
        guideFormInvalid.classList.remove('hide');
        guideFormSendOnce = true;
      }
    })
  }
}

function validateGuideForm() {
  var form = document.forms['guide_form'];

  // email
  var email = form['guide_form[email]'];

  // checkbox
  var agreementContainer = document.getElementsByClassName('agreementContainer')[0];
  var menu = form['guide_form[agreement]'];

  // radio
  var attandee = form['guide_form[attandee]'];
  var time = form['guide_form[time]'];
  var reason = form['guide_form[reason]'];
  var gender = form['guide_form[gender]'];
  var attandeesContainer = document.getElementsByClassName('attandees')[0];
  var timeContainer = document.getElementsByClassName('time')[0];
  var reasonContainer = document.getElementsByClassName('reason')[0];
  var genderContainer = document.getElementsByClassName('gender')[0];

  // text
  var requiredText = [
    form['guide_form[firstname]'],
    form['guide_form[lastname]'],
    form['guide_form[street]'],
    form['guide_form[city]'],
    form['guide_form[teamname]'],
    form['guide_form[date1]'],
    //form['guide_form[date2]'],
    form['guide_form[phone]']
  ];

  // number
  var requiredNumber = [
    form['guide_form[zip]']
  ];

  var valid = true;

  // attandee
  if (!attandee.value) {
    valid = false;
    attandeesContainer.classList.add('invalid');
  } else {
    attandeesContainer.classList.remove('invalid');
  }

  // gender
  if (!time.value) {
    valid = false;
    timeContainer.classList.add('invalid');
  } else {
    timeContainer.classList.remove('invalid');
  }

  // gender
  if (!gender.value) {
    valid = false;
    genderContainer.classList.add('invalid');
  } else {
    genderContainer.classList.remove('invalid');
  }

  // reason
  if (!reason.value) {
    valid = false;
    reasonContainer.classList.add('invalid');
  } else {
    reasonContainer.classList.remove('invalid');
  }

  // text
  for (var i = 0; i < requiredText.length; i++) {
    if (!requiredText[i].value) {
      valid = false;
      requiredText[i].classList.add('invalid');
    } else {
      requiredText[i].classList.remove('invalid');
    }
  }

  // email
  var emailRegex = /^.+@.+\..+$/;

  if (!(email.value && email.value.match(emailRegex))) {
    valid = false;
    email.classList.add('invalid');
  } else {
    email.classList.remove('invalid');
  }

  // number
  for (var i = 0; i < requiredNumber.length; i++) {
    var regex = /^[+]?[0-9]+$/;

    if (!(requiredNumber[i].value && requiredNumber[i].value.match(regex))) {
      valid = false;
      requiredNumber[i].classList.add('invalid');
    } else {
      requiredNumber[i].classList.remove('invalid');
    }
  }

  // checkbox
  if (!menu.checked) {
    valid = false;
    agreementContainer.classList.add('invalid');
  } else {
    agreementContainer.classList.remove('invalid');
  }

  return valid;
}

//*******************************
// Form validation on input changes
//*******************************

var guideForm = document.forms['guide_form'];
var restaurantForm = document.forms['restaurant_form'];
var jobForm = document.forms['job_form'];
var contactForm = document.forms['contact_form'];
var applicationForm = document.forms['application_form'];

var guideFormSendOnce = false;
var restaurantFormSendOnce = false;
var jobFormSendOnce = false;
var contactFormSendOnce = false;
var applicationFormSendOnce = false;

formChanged(guideForm, validateGuideForm);
formChanged(restaurantForm, validateRestaurantForm);
formChanged(jobForm, validateJobForm);
formChanged(contactForm, validateContactForm);
formChanged(applicationForm, validateApplicationForm);

function formChanged(form, validate) {
  if (form) {
    for (var i = 0; i < form.elements.length; i++) {
      form.elements[i].addEventListener('change', function(e) {
        if (guideFormSendOnce || restaurantFormSendOnce || jobFormSendOnce || contactFormSendOnce || applicationFormSendOnce) {
          validate();
        }
      })
    }
  }
}

//*******************************
// Close form dropdowns on select
//*******************************

// guide
closeDropdownOnClick('reason', 'reasonDropdown');
closeDropdownOnClick('attandees', 'attandeeDropdown');
closeDropdownOnClick('time', 'timeDropdown');

// restaurant
closeDropdownOnClick('date', 'dateDropdown');

// application
closeDropdownOnClick('birthDate', 'birthDateDropdown');
closeDropdownOnClick('livingDate', 'livingDateDropdown');
closeDropdownOnClick('daytimeDate', 'daytimeDateDropdown');

function closeDropdownOnClick(containerClass, dropdownId) {
  if (document.getElementsByClassName(containerClass) && document.getElementsByClassName(containerClass)[0]) {
    var inputs = document.getElementsByClassName(containerClass)[0].getElementsByTagName('input')

    for (var i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("click", function(e) {
        $('#' + dropdownId).foundation('close');
      });
    }
  }
}

//*******************************
// Guide form show selection on button
//*******************************

// reason
$('#reasonDropdown').on('hide.zf.dropdown', function(e) {
  displayValue('reason', 'reasonButton')
});

// attandees
$('#attandeeDropdown').on('hide.zf.dropdown', function(e) {
  displayValue('attandee', 'attandeeButton')
});

// time
$('#timeDropdown').on('hide.zf.dropdown', function(e) {
  displayValue('time', 'timeButton')
});

function displayValue(input, button) {
  var inputs = document.forms['guide_form']['guide_form[' + input + ']'];
  var value = document.forms['guide_form']['guide_form[' + input + ']'].value;
  var buttonText = '';

  for (var i = 0; i < inputs.length; i++) {
    if (inputs[i].value == value) {
      buttonText = inputs[i].labels[0].innerText;
    }
  }

  $('#' + button).html(buttonText);
}