import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

require('foundation-sites');

import AOS from 'aos';
import Masonry from 'masonry-layout';
import Modal from 'foundation-sites';
import slick from 'slick-carousel';

require('./lib/cookies-eu-banner');

// shop
import pikaday from 'pikaday';
import moment from 'moment';

require("imports-loader?$=jquery!./lib/select2.js");

$(document).foundation();

//****************************************************************************************************************************
// WEBSITE
//****************************************************************************************************************************

var /* $slickNewsList, */
    $slickJobsList,
    $slickTeamList,
    $slickImageGallery,
    $imageGalleryMasonry;

var Website = {

    init: function () {
        this.initOrDestroySlickSliders();
        if ((location.href.substring(location.href.length - 5, location.href.length) === '#news') || (location.href.substring(location.href.length - 13, location.href.length) === '#ausderkueche')) {
            var scrollOffset = 0, $scrollToElement;

            switch (Foundation.MediaQuery.current) {
                case 'small':
                case 'xsmall':
                case 'medium':
                case 'xmedium':
                    scrollOffset = 25;
                    break;
                default:
                    scrollOffset = $('#offCanvas nav.nav').height() + 15;
                    break;
            }

            if (location.href.substring(location.href.length - 5, location.href.length) === '#news') {
                $scrollToElement = $('h2.newsLink.aboutUs');
            }
            if (location.href.substring(location.href.length - 13, location.href.length) === '#ausderkueche') {
                $scrollToElement = $('h2.newsLink.fromKitchen');
            }

            if ($scrollToElement.length) {
                setTimeout(function () {
                    $('html, body').animate({
                        scrollTop: $scrollToElement.offset().top - scrollOffset
                    }, 1350);
                }, 50);
            }
        }

        $('section').find('.sectionText').each(function () {
            if ($(this).next().length === 0) {
                $(this).addClass('noNextElement');
            }
        });

        this.onScroll();

    },

    onScroll: function () {
        if ($(window).scrollTop() > 0) {
            $navBackground.addClass('isVisible');
            $('#offCanvas').addClass('isScrolled');
        } else {
            $navBackground.removeClass('isVisible');
            $('#offCanvas').removeClass('isScrolled');
        }
    },

    initOrDestroySlickSliders: function () {

        switch (Foundation.MediaQuery.current) {
            case 'small':
            case 'xsmall':
            case 'medium':
            case 'xmedium':

                /*if ($slickNewsList === undefined)
                {
                    $slickNewsList = $('section.newsList.compactView').find('.newsContainer').slick({
                        autoplay: false,
                        arrows: false,
                        dots: false,
                        centerMode: true,
                        centerPadding: '32px'
                    });
                }*/

                if ($slickJobsList === undefined) {
                    $slickJobsList = $('section.jobsList').find('.jobsContainer').each(function () {
                        $(this).slick({
                            autoplay: false,
                            arrows: false,
                            dots: false,
                            centerMode: true,
                            centerPadding: '32px'
                        });
                    });
                }

                if ($slickTeamList === undefined) {
                    $slickTeamList = $('section.teamList').find('.teamContainer').each(function () {
                        $(this).slick({
                            autoplay: false,
                            arrows: false,
                            dots: false,
                            centerMode: true,
                            centerPadding: '32px'
                        });
                    });
                }

                if ($imageGalleryMasonry !== undefined) {
                    $imageGalleryMasonry.destroy();
                }

                if ($slickImageGallery === undefined) {
                    $slickImageGallery = $('section.imageGallery').find('.galleryItems').each(function () {
                        $(this).slick({
                            autoplay: false,
                            arrows: false,
                            dots: false,
                            centerMode: true,
                            centerPadding: '32px'
                        });
                    });
                }

                break;

            default:

                /* if ($slickNewsList !== undefined)
                {
                    $slickNewsList.slick('unslick');
                } */
                if ($slickJobsList !== undefined) {
                    $slickJobsList.slick('unslick');
                }
                if ($slickTeamList !== undefined) {
                    $slickTeamList.slick('unslick');
                }
                if ($slickImageGallery !== undefined) {
                    $slickImageGallery.slick('unslick');
                }

                if ($('section.imageGallery .galleryItems').length) {
                    $imageGalleryMasonry = new Masonry('section.imageGallery .galleryItems');
                }

                break;

        }

    },

    isHomePage: function () {
        return $body.hasClass('home');
    },

    isEMailAddressValid: function (eMail) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(eMail);
    },

    resetForm: function ($form) {
        $form.find('input, textarea').each(function () {
            $(this).val('');
        });
        $form.find('span.radio').removeClass('isChecked');
    },

    validateForm: function ($form, formType) {
        var formIsValid = true;
        $form.find('span[data-dataprotection]').removeClass('error');

        $form.find('input, textarea').each(function () {
            $(this).removeClass('error');
            $(this).parent('label').removeClass('error');

            if ($(this).hasClass('required')) {
                if ($(this).val().length < 2) {
                    $(this).addClass('error');
                    $(this).parent('label').addClass('error');
                    formIsValid = false;
                }
                if ($(this).hasClass('email')) {
                    if (!Website.isEMailAddressValid($(this).val())) {
                        $(this).addClass('error');
                        $(this).parent('label').addClass('error');
                        formIsValid = false;
                    }
                }
            }
        });

        if ($form.find('.cell.otherInvoiceAddress').is(':visible')) {
            $form.find('.cell.otherInvoiceAddress').find('input').each(function () {
                if ($(this).val().length < 2) {
                    $(this).addClass('error');
                    $(this).parent('label').addClass('error');
                    formIsValid = false;
                }
            });
        } else {
            $form.find('.cell.otherInvoiceAddress').find('input').each(function () {
                $(this).val('');
                $(this).removeClass('error');
                $(this).parent('label').removeClass('error');
            });
        }

        if (formType !== 'newsletter') {
            if (!$form.find('span[data-dataprotection]').hasClass('isChecked')) {
                $form.find('span[data-dataprotection]').addClass('error');
                formIsValid = false;
            }
        }

        if (!formIsValid) {
            this.pageScroll($form.offset().top - 200);
        }

        return formIsValid;

    },

    sendContactForm: function ($form) {
        var formData = {};

        $form.find('input, textarea').each(function () {
            formData[$(this).attr('name')] = ($(this).val() === '') ? '-' : $(this).val();
        });

        formData['dailyMenu'] = $form.find('span.radio[data-parameter="dailyMenu"]').hasClass('isChecked') ? 'Ja' : 'Nein';

        $.ajax({
            type: 'POST',
            url: '/contactform/send',
            data: formData,
            cache: false,
            success: function (data) {
                if (data.success) {
                    Website.resetForm($form);
                    $modalLead.text('Vielen Dank für Ihre Kontaktaufnahme');
                    $modalText.text('Ihre eingegebenen Daten wurden erfolgreich gesendet. Wir werden Sie in Kürze kontaktieren.');
                    $modal.foundation('open');
                }
            }
        });
    },

    sendVoucherForm: function ($form) {
        var formData = {};

        $form.find('input, textarea').each(function () {
            formData[$(this).attr('name')] = ($(this).val() === '') ? '-' : $(this).val();
        });

        formData['dailyMenu'] = $form.find('span.radio[data-parameter="dailyMenu"]').hasClass('isChecked') ? 'Ja' : 'Nein';

        $.ajax({
            type: 'POST',
            url: '/voucherform/send',
            data: formData,
            cache: false,
            success: function (data) {
                if (data.success) {
                    Website.resetForm($form);
                    $modalLead.text('Vielen Dank für die Bestellung eines Gutscheins');
                    $modalText.text('Ihre eingegebenen Daten wurden erfolgreich gesendet. Wir werden Ihnen in Kürze den Gutschein zustellen.');
                    $modal.foundation('open');
                }
            }
        });
    },

    registerForNewsletter: function ($form) {
        $.ajax({
            type: 'POST',
            url: '/newsletter/register',
            data: {
                'firstName': $form.find('input[name="firstName"]').val(),
                'lastName': $form.find('input[name="lastName"]').val(),
                'eMail': $form.find('input[name="eMail"]').val()
            },
            cache: false,
            success: function (data) {
                if (data.success) {
                    $form.slideUp(250, function () {
                        $footer.find('.newsletterConfirmationMessage').slideDown(250);
                    });
                }
            }
        });
    },

    pageScroll: function (pixel) {
        $('html, body').animate({
            scrollTop: pixel
        }, 700);
    },

    applyCustomVoucherAmount: function (amount) {
        $('input[name=voucherAmountValue]').val(amount);
        $('input[type="text"][name="voucherAmount"]').val('CHF ' + amount + '.-');
        this.showHideCustomVoucherAmount();
    },

    showHideCustomVoucherAmount: function () {
        if ($('#chooseVoucherAmount').is(':visible')) {
            $('input[name="ownAmount"]').val($('#chooseVoucherAmount').attr('placeholder'));
        }
        $('#chooseVoucherAmount').slideToggle(250);
    }

};

var $body = $('body'),
    $footer = $('footer'),
    $header = $('#offCanvas'),
    //$newsletterForm = $footer.find('.cell.cellNewsletterRegistration').children('form'),
    $newsSlider = $('section.newsSlider > .newsItems'),
    $newsSlider_prevArrow = $('section.homepageView').find('.pager').children('.arrows').children('.prevArrow'),
    $newsSlider_nextArrow = $('section.homepageView').find('.pager').children('.arrows').children('.nextArrow'),
    $newsPager = $('section.newsSlider').find('.greenBox').children('.pager').children('.pageIndex'),
    $newsListArrow = $('section.newsList').find('svg.arrow'),
    $contactForm = $('section.contactAndLocation').find('form'),
    $voucherForm = $('section.orderVoucher').find('form'),
    $imageVideoSlider = $('section.imageVideoSlider'),
    $modal = $('#modal'),
    $modalLead = $modal.children('p.lead'),
    $modalText = $modal.children('p:not(.lead)'),
    $searchIcon = $('nav.nav').find('a#triggerSearch'),
    $searchContainer = $('nav.nav').next('#searchContainer'),
    $hamburger = $('header.mobile-header').find('svg.hamburgerButton'),
    $navBackground = $('#navBackground');

var imageVideoSliderAutplaySpeed = 10000;
var $imageVideoSliderPager = $imageVideoSlider.find('ul.slick-dots');
var imageVideoSliderAnimation = [];

$(function () {
    Website.init();

    /* Newsletter */
    /* $newsletterForm.submit(function(event)
    {
        event.preventDefault();
        if (Website.validateForm($(this), 'newsletter'))
        {
            Website.registerForNewsletter($newsletterForm);
        }
    }); */

    /* Image/Video Slider */
    if ($imageVideoSlider.length) {
        $imageVideoSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $('section.imageVideoSlider').find('li').children('span.pagingItem').finish().css('width', 0);
        }).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            $('section.imageVideoSlider').find('li:not(.slick-active)').children('span.pagingItem').css('width', 0);
            imageVideoSliderAnimation[currentSlide] = $('section.imageVideoSlider').find('li.slick-active').children('span.pagingItem').animate({
                width: '100%',
            }, imageVideoSliderAutplaySpeed, 'linear', function () {

            });
        });

        $imageVideoSlider.slick({
            autoplay: true,
            autoplaySpeed: imageVideoSliderAutplaySpeed,
            arrows: false,
            dots: true,
            speed: 1200,
            fade: true,
            pauseOnHover: false,
            customPaging: function (slider, i) {
                return '<span class="pagingItem">&nbsp;</span>';
            }
        });

    }


    /* News */
    if ($newsSlider.length) {
        $newsSlider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            $newsPager.text((currentSlide ? currentSlide : 0) + 1 + '/' + slick.slideCount);

            if (currentSlide === undefined) {
                currentSlide = 0;
            }

            var $newsItem = $(slick.$slides[currentSlide]).find('.newsItem').children('.box'),
                newsContent = {
                    'claim': $newsItem.children('span').text(),
                    'title': $newsItem.children('strong').text(),
                    'text': $newsItem.children('div').html(),
                    'linktext': ($newsItem.children('a').length) ? $newsItem.children('a').text() : null,
                    'linkhref': ($newsItem.children('a').length) ? $newsItem.children('a').attr('href') : null,
                    'linktarget': ($newsItem.children('a').length) ? $newsItem.children('a').attr('target') : null
                };

            var $newsContent = $('section.newsSlider > .greenBox > .newsContent');
            $newsContent.children('span').text(newsContent.claim);
            $newsContent.children('strong').text(newsContent.title);
            $newsContent.children('div').html(newsContent.text);

            if ((newsContent.linktext === null) && (newsContent.linkhref === null)) {
                $newsContent.children('a').css('visibility', 'hidden');
            } else {
                $newsContent.children('a').css('visibility', 'visible').text(newsContent.linktext).attr('href', newsContent.linkhref).attr('target', newsContent.linktarget);
            }

        });

        $newsSlider.slick({
            autoplay: true,
            autoplaySpeed: 6000,
            arrows: true,
            dots: false,
            speed: 750,
            prevArrow: $newsSlider_prevArrow,
            nextArrow: $newsSlider_nextArrow
        });

    }

    /* News-List */
    $newsListArrow.click(function (event) {
        event.preventDefault();
        $(this).toggleClass('isSelected');
        if ($(this).hasClass('isSelected')) {
            $(this).next('.text').slideDown(350);
            $(this).prev('.teaser').animate({'padding-bottom': '24px'}, 350);
        } else {
            $(this).next('.text').slideUp(350);
            $(this).prev('.teaser').animate({'padding-bottom': '50px'}, 350);
        }
    });

    /* Contact-Form */
    $contactForm.submit(function (event) {
        event.preventDefault();
        if (Website.validateForm($(this), 'contact')) {
            Website.sendContactForm($(this));
        }
    });
    $contactForm.find('span.radio').click(function () {
        $(this).toggleClass('isChecked');
    });

    /* Voucher-Form */
    $voucherForm.submit(function (event) {
        event.preventDefault();
        if (Website.validateForm($(this), 'voucher')) {
            Website.sendVoucherForm($(this));
        }
    });
    $voucherForm.find('span.radio').click(function () {
        $(this).toggleClass('isChecked');

        if ($(this).data('parameter') === 'otherInvoiceAddress') {
            if ($(this).hasClass('isChecked')) {
                $voucherForm.find('.cell.otherInvoiceAddress').slideDown(250);
            } else {
                $voucherForm.find('.cell.otherInvoiceAddress').slideUp(250);
            }
        }

    });

    $('input[type="text"][name="voucherAmount"]').click(function (event) {
        event.preventDefault();
        Website.showHideCustomVoucherAmount();
    });

    $('#chooseVoucherAmount').children('.predefinedAmount').children('span').children('a').click(function (event) {
        event.preventDefault();
        Website.applyCustomVoucherAmount($(this).parent('span').data('amount'));
    });

    /* Search (Desktop) */
    $searchIcon.click(function (event) {
        event.preventDefault();
        if ($searchContainer.is(':visible')) {
            $searchContainer.slideUp(500, function () {
                $header.removeClass('isScrolled');
            });
        } else {
            $header.addClass('isScrolled');
            setTimeout(function () {
                $searchContainer.slideDown(500);
                $searchContainer.find('input[name="search"]').focus();
            }, 250);
        }
    });

    $('input.onlyNumbersAllowed').keydown(function (event) {
        var key = event.charCode || event.keyCode || 0;
        if (key === 13) {
            Website.applyCustomVoucherAmount($(this).val());
            return false;
        }
        return (
            key === 8 ||
            key === 9 ||
            key === 46 ||
            key === 190 ||
            (key >= 35 && key <= 40) ||
            (key >= 48 && key <= 57) ||
            (key >= 96 && key <= 105)
        );
    });

    /* Mobile-Navigation */
    $hamburger.click(function (event) {
        event.preventDefault();
        $(this).parent().parent().parent('header').toggleClass('menuShown');
    });

});

$(window).on('load', function (event) {
    setTimeout(function () {
        $('body > #loader').fadeOut(350);
    }, 125);
});

$(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
    Website.initOrDestroySlickSliders();
});

$(window).scroll(function (event) {
    Website.onScroll();
});

//****************************************************************************************************************************
// CUSTOM WEBSITE
//****************************************************************************************************************************

//*******************************
// GLightbox
//*******************************

import GLightbox from 'glightbox';

const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
    selector: '.glightbox'
});

//*******************************
// slick slider detail
//*******************************

var $slickSliderDetailOptions = {
    // small up
    dots: false,
    arrows: true,
    centerMode: true,
    centerPadding: '28px',
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 6000,
    pauseOnFocus: false,
    pauseOnHover: false,
    mobileFirst: true,
    focusOnSelect: true,
    swipeToSlide: true,
    responsive: [
        {
            // medium up
            breakpoint: 820,
            settings: {
                centerMode: false,
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            // large up
            breakpoint: 1024,
            settings: {
                centerMode: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                swipe: false
            }
        }
    ]
}

$('.slick-website-detail').slick($slickSliderDetailOptions);

//*******************************
// slick slider timeline
//*******************************

var $slickSliderTimelineOptions = {
    // small up
    dots: false,
    arrows: true,
    centerMode: true,
    centerPadding: '28px',
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 6000,
    pauseOnFocus: false,
    pauseOnHover: false,
    mobileFirst: true,
    focusOnSelect: true,
    swipeToSlide: true,
    responsive: [
        {
            // custom
            breakpoint: 500,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            }
        },
        /* {
          // medium up
          breakpoint: 640,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          }
        },
        {
          // large up
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          }
        } */
    ]
}

$('.slick-website-timeline').slick($slickSliderTimelineOptions);

// go to fifth element without animation
$('.slick-website-timeline').slick('slickGoTo', 5, true);

// replace text below slider for mobile
$('.slick-website-timeline').on('afterChange', function (event, slick, currentSlide) {
    var timelineBottomText = slick.$slides[currentSlide].getElementsByClassName("text")[0];
    var timelineBottomTextContainer = document.getElementById('timelineBottomText');

    if (timelineBottomText) {
        timelineBottomTextContainer.innerHTML = timelineBottomText.innerHTML;
    } else {
        timelineBottomTextContainer.innerHTML = '';
    }
});

//*******************************
// slick slider apprentice / work / certificate
//*******************************

var $slickSliderApprenticeOptions = {
    // small up
    dots: false,
    arrows: true,
    centerMode: true,
    centerPadding: '28px',
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 6000,
    pauseOnFocus: false,
    pauseOnHover: false,
    mobileFirst: true,
    responsive: [
        {
            // medium up
            breakpoint: 640,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                centerMode: false,
            }
        },
        {
            // large up
            breakpoint: 1024,
            settings: "unslick"
        }
    ]
}

$('.slick-website-apprentice').slick($slickSliderApprenticeOptions);
$('.slick-website-work').slick($slickSliderApprenticeOptions);
$('.slick-website-certificate').slick($slickSliderApprenticeOptions);
$('.slick-website-integration').slick($slickSliderApprenticeOptions);
$('.slick-website-job').slick($slickSliderApprenticeOptions);
$('.slick-website-news').slick($slickSliderApprenticeOptions);

//*******************************
// switch from list to slider below 1200px
//     work, apprentice, certificate
//*******************************

// slider below 1200, list above 1200
window.onresize = function () {
    mobileSlider();
}

mobileSlider();

function mobileSlider() {
    var slickWebsiteWork = document.querySelector('.slick-website-work');
    var slickWebsiteCertificate = document.querySelector('.slick-website-certificate');
    var slickWebsiteApprentice = document.querySelector('.slick-website-apprentice');
    var slickWebsiteIntegration = document.querySelector('.slick-website-integration');
    var slickWebsiteJob = document.querySelector('.slick-website-job');
    var slickWebsiteNews = document.querySelector('.slick-website-news');

    // work
    if (slickWebsiteWork && !slickWebsiteWork.classList.contains('slick-initialized') && window.innerWidth <= 1024) {
        $('.slick-website-work').slick($slickSliderApprenticeOptions);
    }

    // certificate
    if (slickWebsiteCertificate && !slickWebsiteCertificate.classList.contains('slick-initialized') && window.innerWidth <= 1024) {
        $('.slick-website-certificate').slick($slickSliderApprenticeOptions);
    }

    // apprentice
    if (slickWebsiteApprentice && !slickWebsiteApprentice.classList.contains('slick-initialized') && window.innerWidth <= 1024) {
        $('.slick-website-apprentice').slick($slickSliderApprenticeOptions);
    }

    // integration
    if (slickWebsiteIntegration && window.innerWidth <= 1024) {
        // if (slickWebsiteIntegration && !slickWebsiteIntegration.classList.contains('slick-initialized') && window.innerWidth <= 1024) {
        getAllIntegration();
        // $('.slick-website-integration').slick($slickSliderApprenticeOptions);
    } else {
        getFirstIntegration();
    }

    // job
    if (slickWebsiteJob && window.innerWidth <= 1024) {
        //if (slickWebsiteJob && !slickWebsiteJob.classList.contains('slick-initialized') && window.innerWidth <= 1024) {
        getAllJob();
        // $('.slick-website-job').slick($slickSliderApprenticeOptions);
    }

    // news
    if (slickWebsiteNews && window.innerWidth <= 1024) {
        // if (slickWebsiteNews && !slickWebsiteNews.classList.contains('slick-initialized') && window.innerWidth <= 1024) {
        getAllNews();
        // $('.slick-website-news').slick($slickSliderApprenticeOptions);
    }
}

//*******************************
// slick slider solutions
//*******************************

var $slickSliderSolutionsOptions = {
    dots: false,
    arrows: true,
    centerMode: false,
    centerPadding: '28px',
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    autoplaySpeed: 6000,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
        {
            // xxlarge down
            breakpoint: 1599,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            // xlarge down
            breakpoint: 1440,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            // large down
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            // xmedium down
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            // xsmall down
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
            }
        }
    ]
}

$('.slick-website-solutions').slick($slickSliderSolutionsOptions);

//*******************************
// Smooth scroll to anchor
//*******************************

$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    if (!event.target.classList.contains('accordion-title')) {
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    }
});

//*******************************
// Hide mobile navigation on clicking solution links
//*******************************

hideNavigationSolutionLinks();

function hideNavigationSolutionLinks() {
    var links = document.getElementsByClassName('solution-link');

    for (var i = 0; i < links.length; i++) {
        links[i].addEventListener('click', function (e) {
            $('#offCanvas').foundation('close');
        })
    }
}

//*******************************
// Toggle read speaker button
//*******************************

toggleReadSpeakerButton();

function toggleReadSpeakerButton() {
    var readPageToggle = document.getElementById('readPageToggle');
    var readPageText = document.getElementById('readPageText');
    var readPageTarget = document.getElementById('readPageTarget');

    var readPageToggleModal = document.getElementById('readPageToggleModal');
    var readPageTextModal = document.getElementById('readPageTextModal');
    var readPageTargetModal = document.getElementById('readPageTargetModal');

    var jobFormToggler = document.getElementById('jobFormToggler');
    var jobForm = document.getElementById('jobForm');
    //var jobFormTitle = document.getElementById('jobFormTitle');
    var jobDescription = document.getElementById('jobDescription');
    //var jobDescriptionTitle = document.getElementById('jobDescriptionTitle');
    //var jobNewsletter = document.getElementById('jobNewsletter');
    //var jobNewsletterToggler = document.getElementById('jobNewsletterToggler');

    // area
    if (readPageToggle && readPageText && readPageTarget) {
        readPageToggle.addEventListener('click', function (e) {
            e.preventDefault();

            readPageText.classList.add('hide');
            readPageTarget.classList.remove('hide');

            // show job detail content
            if (jobFormToggler && jobForm) {
                jobFormToggler.classList.add('hide');
                jobForm.classList.remove('hide');
                //jobFormTitle.classList.remove('hide');
                //jobDescription.classList.add('hide');
                //jobDescriptionTitle.classList.add('hide');
                //jobNewsletterToggler.classList.add('hide');
                //jobNewsletter.classList.remove('hide');
            }

        })
    }

    // modal
    if (readPageToggleModal && readPageTextModal && readPageTargetModal) {
        readPageToggleModal.addEventListener('click', function (e) {
            e.preventDefault();

            readPageTextModal.classList.add('hide');
            readPageTargetModal.classList.remove('hide');

            // show job detail content
            if (toggler && jobForm) {
                toggler.classList.add('hide');
                jobForm.classList.remove('hide');
                jobDescription.classList.remove('hide');
            }

        })
    }
}

//*******************************
// Reset read speaker button and job detail
//*******************************

function resetReadSpeakerButton() {
    var readPageText = document.getElementById('readPageTextModal');
    var readPageTarget = document.getElementById('readPageTargetModal');
    var toggler = document.getElementById('jobFormToggler');
    var jobForm = document.getElementById('jobForm');

    if (readPageText && readPageTarget) {
        readPageText.classList.remove('hide');
        readPageTarget.classList.add('hide');
    }

    if (toggler && jobForm) {
        toggler.classList.remove('hide');
        jobForm.classList.add('hide');
    }

}

//*******************************
// Detail modal with ajax
//*******************************

var newsDetailLinks = document.getElementsByClassName('newsDetailLink');
var jobDetailLinks = document.getElementsByClassName('jobDetailLink');
var integrationDetailLinks = document.getElementsByClassName('integrationDetailLink');

detailModal(newsDetailLinks);
detailModal(jobDetailLinks);
detailModal(integrationDetailLinks);

// insert object data into modal
function detailModal(detailLinks) {
    var modalContent = document.getElementById('modalContent');

    if (modalContent) {
        for (var i = 0; i < detailLinks.length; i++) {
            detailLinks[i].addEventListener('click', function (e) {
                e.preventDefault();

                modalContent.innerHTML = '';

                resetReadSpeakerButton();

                var url = this.href + '?ajax=1';

                fetch(url)
                    .then(function (response) {
                        if (!response.ok) {
                            throw Error(response.statusText);
                        }
                        return response.text();
                    }).then(function (response) {
                    modalContent.innerHTML = response;
                    getNextObjectDetail();
                    getPreviousObjectDetail();
                    jobFormToggler();
                    formsJSActive();
                    fileInputs();
                    toggleReadSpeakerButton();
                    sendJobForm();

                    $('.slick-website-detail').slick($slickSliderDetailOptions);
                    const lightbox = GLightbox({
                        touchNavigation: true,
                        loop: true,
                        autoplayVideos: true,
                        selector: '.glightbox'
                    });

                    //submitJobApplication();
                }).catch(function (error) {
                    console.log(error);
                });

            })
        }
    }
}

// fix for modal not removing .is-reveal-open from <html> on close
$('#modal').on('closed.zf.reveal', function (e) {
    document.getElementsByTagName('html')[0].classList.remove('is-reveal-open');
});

//*******************************
// Submit job application with ajax
//*******************************

//submitJobApplication();

// insert object data into modal
function submitJobApplication() {
    var modalContent = document.getElementById('modalContent');
    var submitButton = document.getElementById('job_form_submit');
    var jobForm = document.forms['jobForm'];

    if (modalContent && jobForm && submitButton) {
        submitButton.addEventListener('click', function (e) {
            e.preventDefault();

            var url = jobForm.action + '?ajax=1';

            var data = new URLSearchParams();
            for (var pair of new FormData(jobForm)) {
                data.append(pair[0], pair[1]);
            }

            var input = document.querySelector('input[type="file"]');
            data.append('file1', input.files[0]);
            /* data.append('file2', input.files[1]);
            data.append('file3', input.files[2]);
            data.append('file4', input.files[3]); */

            fetch(url, {
                method: 'post',
                body: data
            })
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.text();
                }).then(function (response) {
                var jobForm = document.forms['jobForm'];

                if (jobForm) {
                    modalContent.innerHTML = response;
                    getNextObjectDetail();
                    getPreviousObjectDetail();
                    jobFormToggler();
                    formsJSActive();
                    fileInputs();
                }
            }).catch(function (error) {
                console.log(error);
            });

        })
    }
}

//*******************************
// Job application file input name and size
//*******************************

fileInputs();

function fileInputs() {
    var fileInputs = document.querySelectorAll('.original-file-input');

    for (var i = 0; i < fileInputs.length; i++) {
        fileInputs[i].addEventListener('change', function (e) {
            var file = e.target.files[0];
            if (file) {
                var fileDetails = document.querySelector('.file-' + e.target.dataset.file);
                var fileSize = Math.round(file.size / 1024 / 1024 * 100) / 100;

                fileDetails.innerHTML = file.name + ' (' + fileSize + ' MB)';
            }
        })
    }
}

//*******************************
// Job list pagination with ajax
//*******************************

getMoreJobs();

function getMoreJobs() {
    var moreJobsLink = document.getElementById('moreJobsLink');
    var jobList = document.getElementById('jobList');


    if (moreJobsLink && jobList) {
        moreJobsLink.addEventListener('click', function (e) {
            e.preventDefault();

            var url = moreJobsLink.dataset.url;

            fetch(url)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.text();
                }).then(function (response) {
                // remove old button
                moreJobsLink.remove();

                jobList.insertAdjacentHTML('beforeend', response);

                // move new button
                var newButton = document.getElementById('moreJobsLink');
                //var jobListContainer = document.getElementById('jobListContainer');
                var moreJobsContainer = document.getElementById('moreJobsContainer');

                if (newButton && moreJobsContainer) {
                    newButton.remove();
                    moreJobsContainer.appendChild(newButton);
                }

                // add event listener on new button
                getMoreJobs();

                var jobDetailLinks = document.getElementsByClassName('jobDetailLink');
                detailModal(jobDetailLinks);

            }).catch(function (error) {
                console.log(error);
            });
        })
    }

}

//*******************************
// News list pagination with ajax
//*******************************

getMoreNews();

function getMoreNews() {
    var moreNewsLink = document.getElementById('moreNewsLink');
    var newsList = document.getElementById('newsList');

    if (moreNewsLink && newsList) {
        moreNewsLink.addEventListener('click', function (e) {
            e.preventDefault();

            var url = moreNewsLink.dataset.url;

            fetch(url)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.text();
                }).then(function (response) {
                // remove old button
                moreNewsLink.remove();

                newsList.insertAdjacentHTML('beforeend', response);

                // move new button
                var newButton = document.getElementById('moreNewsLink');
                var newsListContainer = document.getElementById('newsListContainer');

                if (newButton && newsListContainer) {
                    newButton.remove();
                    newsListContainer.appendChild(newButton);
                }

                // add event listener on new button
                getMoreNews();

                var newsDetailLinks = document.getElementsByClassName('newsDetailLink');
                detailModal(newsDetailLinks);

            }).catch(function (error) {
                console.log(error);
            });
        })
    }

}

//*******************************
// Integration list pagination with ajax
//*******************************

// getMoreIntegration();

function getMoreIntegration() {
    var moreIntegrationLink = document.getElementById('moreIntegrationLink');
    var moreIntegrationLinkContainer = document.getElementById('moreIntegrationLinkContainer');
    var integrationList = document.getElementById('integrationList');

    if (moreIntegrationLink && integrationList) {
        moreIntegrationLink.addEventListener('click', function (e) {
            e.preventDefault();

            var url = moreIntegrationLink.dataset.url + '&doc=' + moreIntegrationLink.href.split('?')[0];

            fetch(url)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.text();
                }).then(function (response) {
                // remove old button
                moreIntegrationLink.remove();

                // add new data
                integrationList.insertAdjacentHTML('beforeend', response);

                // move new button
                var newButton = document.getElementById('moreIntegrationLink');
                //var integrationListContainer = document.getElementById('integrationListContainer');

                if (newButton && moreIntegrationLinkContainer) {
                    newButton.remove();
                    moreIntegrationLinkContainer.appendChild(newButton);
                }

                // add event listener on new button
                getMoreIntegration();

                var integrationDetailLinks = document.getElementsByClassName('integrationDetailLink');
                detailModal(integrationDetailLinks);

            }).catch(function (error) {
                console.log(error);
            });
        })
    }

}

//*******************************
// Integration list first load
//*******************************

//getFirstIntegration();

function getFirstIntegration() {

    var moreIntegrationLinkContainer = document.getElementById('moreIntegrationLinkContainer');
    var moreIntegrationLink = document.getElementById('moreIntegrationLink');
    var integrationList = document.getElementById('integrationList');

    if (moreIntegrationLinkContainer && integrationList && moreIntegrationLink) {
        var url = moreIntegrationLink.dataset.url + '&doc=' + moreIntegrationLink.href.split('?')[0];

        fetch(url)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.text();
            }).then(function (response) {
            // remove old button
            moreIntegrationLink.remove();

            // add new data
            integrationList.insertAdjacentHTML('beforeend', response);

            // move new button
            //var newButton = document.getElementById('moreIntegrationLink');
            //var integrationListContainer = document.getElementById('integrationListContainer');

            /* if (newButton && integrationListContainer) {
              newButton.remove();
              integrationListContainer.appendChild(newButton);
            } */

            // place new button
            var newButton = document.getElementById('moreIntegrationLink');
            //var integrationListContainer = document.getElementById('integrationListContainer');

            if (newButton && moreIntegrationLinkContainer) {
                newButton.remove();
                moreIntegrationLinkContainer.appendChild(newButton);
            }

            // add event listener on new button
            getMoreIntegration();

            var integrationDetailLinks = document.getElementsByClassName('integrationDetailLink');
            detailModal(integrationDetailLinks);

        }).catch(function (error) {
            console.log(error);
        });
    }

}

//*******************************
// Integration list load everything for mobile slider
//*******************************

function getAllIntegration() {
    var moreIntegrationLink = document.getElementById('moreIntegrationLink');
    var integrationList = document.getElementById('integrationList');

    if (moreIntegrationLink && integrationList) {
        var url = moreIntegrationLink.dataset.url + '&all=1';

        fetch(url)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.text();
            }).then(function (response) {
            // remove old button
            moreIntegrationLink.remove();

            $('.slick-website-integration').slick('unslick');

            integrationList.innerHTML = response;

            /* // move new button
            var newButton = document.getElementById('moreIntegrationLink');
            var integrationListContainer = document.getElementById('integrationListContainer');

            if (newButton && integrationListContainer) {
              newButton.remove();
              integrationListContainer.appendChild(newButton);
            }

            // add event listener on new button
            getMoreIntegration(); */

            var newButton = document.getElementById('moreIntegrationLink');

            if (newButton) {
                newButton.remove();
            }

            var integrationDetailLinks = document.getElementsByClassName('integrationDetailLink');
            detailModal(integrationDetailLinks);

            $('.slick-website-integration').slick($slickSliderApprenticeOptions);

        }).catch(function (error) {
            console.log(error);
        });
    }

}

//*******************************
// Job list load everything for mobile slider
//*******************************

function getAllJob() {
    var moreJobsLink = document.getElementById('moreJobsLink');
    var jobList = document.getElementById('jobList');

    if (moreJobsLink && jobList) {
        var url = moreJobsLink.dataset.url + '&all=1';

        fetch(url)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.text();
            }).then(function (response) {
            // remove old button
            moreJobsLink.remove();

            $('.slick-website-job').slick('unslick');

            jobList.innerHTML = response;

            /* // move new button
            var newButton = document.getElementById('moreJobsLink');
            var jobListContainer = document.getElementById('jobListContainer');

            if (newButton && jobListContainer) {
              newButton.remove();
              jobListContainer.appendChild(newButton);
            }

            // add event listener on new button
            getMoreJobs(); */

            var newButton = document.getElementById('moreJobsLink');

            if (newButton) {
                newButton.remove();
            }

            var jobDetailLinks = document.getElementsByClassName('jobDetailLink');
            detailModal(jobDetailLinks);

        }).then(function (response) {
            $('.slick-website-job').slick($slickSliderApprenticeOptions);

        }).catch(function (error) {
            console.log(error);
        });
    }

}

//*******************************
// News list load everything for mobile slider
//*******************************

function getAllNews() {
    var moreNewsLink = document.getElementById('moreNewsLink');
    var newsList = document.getElementById('newsList');

    if (moreNewsLink && newsList) {
        var url = moreNewsLink.dataset.url + '&all=1';

        fetch(url)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.text();
            }).then(function (response) {
            // remove old button
            moreNewsLink.remove();

            $('.slick-website-news').slick('unslick');

            newsList.innerHTML = response;

            /* // move new button
            var newButton = document.getElementById('moreNewsLink');
            var newsListContainer = document.getElementById('newsListContainer');

            if (newButton && newsListContainer) {
              newButton.remove();
              newsListContainer.appendChild(newButton);
            }

            // add event listener on new button
            getMoreNews(); */

            var newButton = document.getElementById('moreNewsLink');

            if (newButton) {
                newButton.remove();
            }

            var newsDetailLinks = document.getElementsByClassName('newsDetailLink');
            detailModal(newsDetailLinks);

        }).then(function (response) {
            $('.slick-website-news').slick($slickSliderApprenticeOptions);

        }).catch(function (error) {
            console.log(error);
        });
    }

}

//*******************************
// Next object detail with ajax
//*******************************

getNextObjectDetail();

// insert object data into modal
function getNextObjectDetail() {
    var modalContent = document.getElementById('modalContent');
    var nextObjectDetailLink = document.getElementById('nextObjectDetailLink');

    if (modalContent && nextObjectDetailLink) {
        nextObjectDetailLink.addEventListener('click', function (e) {
            e.preventDefault();

            resetReadSpeakerButton();

            var url = nextObjectDetailLink.href + '?ajax=1';

            fetch(url)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.text();
                }).then(function (response) {
                modalContent.innerHTML = response;

                getNextObjectDetail();
                getPreviousObjectDetail();
                jobFormToggler();
            }).catch(function (error) {
                console.log(error);
            });

        })
    }

}

//*******************************
// Previous object detail with ajax
//*******************************

getPreviousObjectDetail();

// insert object data into modal
function getPreviousObjectDetail() {
    var modalContent = document.getElementById('modalContent');
    var previousObjectDetailLink = document.getElementById('previousObjectDetailLink');

    if (modalContent && previousObjectDetailLink) {
        previousObjectDetailLink.addEventListener('click', function (e) {
            e.preventDefault();

            resetReadSpeakerButton();

            var url = previousObjectDetailLink.href + '?ajax=1';

            fetch(url)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.text();
                }).then(function (response) {
                modalContent.innerHTML = response;

                getNextObjectDetail();
                getPreviousObjectDetail();
                jobFormToggler();
            }).catch(function (error) {
                console.log(error);
            });

        })
    }

}

//*******************************
// Toggle job application form and newsletter
//*******************************

jobFormToggler();

function jobFormToggler() {
    var jobFormToggler = document.getElementById('jobFormToggler');
    var jobForm = document.getElementById('jobForm');

    //var newsletterToggler = document.getElementById('jobNewsletterToggler');
    //var jobNewsletter = document.getElementById('jobNewsletter');

    // form
    if (jobFormToggler && jobForm) {
        jobFormToggler.addEventListener('click', function (e) {
            e.preventDefault();

            jobForm.classList.remove('hide');
            //jobNewsletter.classList.add('hide');
            //jobFormToggler.classList.add('hide');
            jobFormToggler.classList.add('active');
            //newsletterToggler.classList.remove('active');
        })
    }

    // newsletter
    /* if (newsletterToggler && jobNewsletter) {
      newsletterToggler.addEventListener('click', function(e) {
        e.preventDefault();

        jobNewsletter.classList.remove('hide');
        jobForm.classList.add('hide');
        //newsletterToggler.classList.add('hide');
        jobFormToggler.classList.remove('active');
        newsletterToggler.classList.add('active');
      })
    } */
}

//*******************************
// Toggle raisenow donation element
//*******************************

raisenowToggler();

function raisenowToggler() {
    var toggler1 = document.getElementById('donateNowLink1');
    var toggler2 = document.getElementById('donateNowLink2');
    var raisenow = document.getElementById('raisenow');

    if (toggler1 && raisenow) {
        toggler1.addEventListener('click', function (e) {
            //e.preventDefault();

            raisenow.classList.remove('hide');
        })
    }

    if (toggler2 && raisenow) {
        toggler2.addEventListener('click', function (e) {
            //e.preventDefault();

            raisenow.classList.remove('hide');
        })
    }
}

//*******************************
// Javascript is active for forms
//*******************************

formsJSActive();

function formsJSActive() {
    var job_form_filled = document.getElementById('job_form_filled');
    var contact_form_filled = document.getElementById('contact_form_filled');
    var guide_form_filled = document.getElementById('guide_form_filled');
    var restaurant_form_filled = document.getElementById('restaurant_form_filled');
    var application_form_filled = document.getElementById('application_form_filled');

    // job
    if (job_form_filled) {
        job_form_filled.value = 'jsfilled';
    }

    // contact
    if (contact_form_filled) {
        contact_form_filled.value = 'jsfilled';
    }

    // guide
    if (guide_form_filled) {
        guide_form_filled.value = 'jsfilled';
    }

    // restaurant
    if (restaurant_form_filled) {
        restaurant_form_filled.value = 'jsfilled';
    }

    // application
    if (application_form_filled) {
        application_form_filled.value = 'jsfilled';
    }
}

//*******************************
// Application form datepicker
//*******************************

// birthday date
var applicationFormBirthDateInput = document.getElementById('application_form_birthdate');
var applicationFormBirthDateContainer = document.getElementById('applicationBirthDateContainer');

if (applicationFormBirthDateInput && applicationFormBirthDateContainer) {

    // date tomorrow
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // pikaday config
    var applicationBirthDatepicker = new pikaday({
        field: applicationFormBirthDateInput,
        container: applicationFormBirthDateContainer,
        bound: false,
        onSelect: function () {
            this.hide();
            $('#birthDateDropdown').foundation('close');
        },
        format: 'DD.MM.YYYY',
        disableWeekends: false,
        //minDate: tomorrow,
        firstDay: 1,
        i18n: {
            previousMonth: 'Previous Month',
            nextMonth: 'Next Month',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
        }
    });

    // hide pikaday when dropdown closes
    $('#birthDateDropdown').on('show.zf.dropdown', function (e) {
        applicationBirthDatepicker.show();
    });
}

// living date
var applicationFormLivingInput = document.getElementById('application_form_livingDate');
var applicationFormLivingDateContainer = document.getElementById('applicationLivingDateContainer');

if (applicationFormLivingInput && applicationFormLivingDateContainer) {

    // date tomorrow
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // pikaday config
    var applicationLivingDatepicker = new pikaday({
        field: applicationFormLivingInput,
        container: applicationFormLivingDateContainer,
        bound: false,
        onSelect: function () {
            this.hide();
            $('#livingDateDropdown').foundation('close');
        },
        format: 'DD.MM.YYYY',
        disableWeekends: false,
        minDate: tomorrow,
        firstDay: 1,
        i18n: {
            previousMonth: 'Previous Month',
            nextMonth: 'Next Month',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
        }
    });

    // hide pikaday when dropdown closes
    $('#livingDateDropdown').on('show.zf.dropdown', function (e) {
        applicationLivingDatepicker.show();
    });
}

// daytime date
var applicationFormDaytimeInput = document.getElementById('application_form_daytimeDate');
var applicationFormDaytimeDateContainer = document.getElementById('applicationDaytimeDateContainer');

if (applicationFormDaytimeInput && applicationFormDaytimeDateContainer) {

    // date tomorrow
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // pikaday config
    var applicationDaytimeDatepicker = new pikaday({
        field: applicationFormDaytimeInput,
        container: applicationFormDaytimeDateContainer,
        bound: false,
        onSelect: function () {
            this.hide();
            $('#daytimeDateDropdown').foundation('close');
        },
        format: 'DD.MM.YYYY',
        disableWeekends: false,
        minDate: tomorrow,
        firstDay: 1,
        i18n: {
            previousMonth: 'Previous Month',
            nextMonth: 'Next Month',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
        }
    });

    // hide pikaday when dropdown closes
    $('#daytimeDateDropdown').on('show.zf.dropdown', function (e) {
        applicationDaytimeDatepicker.show();
    });
}

//*******************************
// Restaurant form datepicker
//*******************************

// date
var restaurantFormDateInput = document.getElementById('restaurant_form_date');
var restaurantFormDateContainer = document.getElementById('restaurantDateContainer');

if (restaurantFormDateInput && restaurantFormDateContainer) {

    // date tomorrow
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // pikaday config
    var restaurantDatepicker = new pikaday({
        field: restaurantFormDateInput,
        container: restaurantFormDateContainer,
        bound: false,
        onSelect: function () {
            this.hide();
            $('#dateDropdown').foundation('close');
        },
        format: 'DD.MM.YYYY',
        disableWeekends: true,
        minDate: tomorrow,
        firstDay: 1,
        i18n: {
            previousMonth: 'Previous Month',
            nextMonth: 'Next Month',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
        }
    });

    // hide pikaday when dropdown closes
    $('#dateDropdown').on('show.zf.dropdown', function (e) {
        restaurantDatepicker.show();
    });
}

//*******************************
// Guide form datepicker
//*******************************

// date 1
var guideFormDate1Input = document.getElementById('guide_form_date1');
var guideFormDate1Container = document.getElementById('guideDate1Container');

if (guideFormDate1Input && guideFormDate1Container) {

    // date tomorrow
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // pikaday config
    var guideDate1picker = new pikaday({
        field: guideFormDate1Input,
        container: guideFormDate1Container,
        bound: false,
        onSelect: function () {
            this.hide();
            $('#date1Dropdown').foundation('close');
        },
        format: 'DD.MM.YYYY',
        disableWeekends: true,
        minDate: tomorrow,
        firstDay: 1,
        i18n: {
            previousMonth: 'Previous Month',
            nextMonth: 'Next Month',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
        },
    });

    // hide pikaday when dropdown closes
    $('#date1Dropdown').on('show.zf.dropdown', function (e) {
        guideDate1picker.show();
    });
}

// date 2
var guideFormDate2Input = document.getElementById('guide_form_date2');
var guideFormDate2Container = document.getElementById('guideDate2Container');

if (guideFormDate2Input && guideFormDate2Container) {

    // date tomorrow
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // pikaday config
    var guideDate2picker = new pikaday({
        field: guideFormDate2Input,
        container: guideFormDate2Container,
        bound: false,
        onSelect: function () {
            this.hide();
            $('#date2Dropdown').foundation('close');
        },
        format: 'DD.MM.YYYY',
        disableWeekends: true,
        minDate: tomorrow,
        firstDay: 1,
        i18n: {
            previousMonth: 'Previous Month',
            nextMonth: 'Next Month',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
        },
    });

    // hide pikaday when dropdown closes
    $('#date2Dropdown').on('show.zf.dropdown', function (e) {
        guideDate2picker.show();
    });
}

//****************************************************************************************************************************
// SHOP
//****************************************************************************************************************************

//*******************************
// Mobile burger
//*******************************

$('#offCanvas').on('opened.zf.offCanvas', function (e) {
    $('#menuButton').addClass('is-active');
});

$('#offCanvas').on('closed.zf.offCanvas', function (e) {
    $('#menuButton').removeClass('is-active');
});


//*******************************
// Pikaday: checkout pickup date
//*******************************

var pickupDate = document.getElementById('delivery_address_form_pickupDate');

if (pickupDate) {

    // date tomorrow
    const today = new Date();
    //const inThreeDays = new Date(today);
    //inThreeDays.setDate(inThreeDays.getDate() + 3);

    // add days to date excluding weekends
    function calcWorkingDays(fromDate, days) {
        var count = 0;

        while (count < days) {
            fromDate.setDate(fromDate.getDate() + 1);

            if (fromDate.getDay() != 0 && fromDate.getDay() != 6) { // Skip weekends
                count++;
            }
        }

        return fromDate;
    }

    var inThreeDays = calcWorkingDays(today, 3);

    // pikaday config
    var picker = new pikaday({
        field: pickupDate,
        format: 'DD.MM.YYYY',
        disableWeekends: true,
        minDate: inThreeDays,
        firstDay: 1,
        i18n: {
            previousMonth: 'Previous Month',
            nextMonth: 'Next Month',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
        },
    });
}

//*********************************
// slick slider hero
//*********************************

var $optsShopHero = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    //fade: false,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnFocus: false,
    pauseOnHover: true,
    pauseOnDotsHover: false
}

$('.slick-shop-hero').slick($optsShopHero);

//*******************************
// slick slider listing
//*******************************

var $optsShopListing = {
    dots: false,
    arrows: true,
    centerMode: false,
    centerPadding: '28px',
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 600000,
    pauseOnFocus: false,
    pauseOnHover: false,
    //prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="">Previous</button>',
    //nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="">Next</button>',
    responsive: [
        {
            // xxlarge down
            breakpoint: 1599,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            // xlarge down
            breakpoint: 1440,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                //arrows: false,
                //centerMode: true,
                //centerPadding: '28px',
            }
        },
        {
            // large down
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                //arrows: false,
                //centerMode: true,
                //centerPadding: '28px',
            }
        },
        {
            // xmedium down
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                //arrows: false,
                //centerMode: true,
                //centerPadding: '28px',
            }
        },
        {
            // xsmall down
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                //arrows: false,
                centerMode: true,
                //centerPadding: '28px',
            }
        }
    ]
}

$('.slick-shop-listing').slick($optsShopListing);

//*******************************
// slick slider detail large
//*******************************

var $optsShopDetailLarge = {
    dots: true,
    arrows: false,
    centerMode: false,
    centerPadding: '28px',
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 6000,
    pauseOnFocus: false,
    pauseOnHover: false,
    asNavFor: '.slick-shop-detail-small',
    responsive: [
        {
            // xxlarge down
            breakpoint: 1599,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            // xlarge down
            breakpoint: 1440,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,

            }
        },
        {
            // large down
            breakpoint: 1200,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,

            }
        },
        {
            // xmedium down
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,

            }
        },
        {
            // xsmall down
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,

            }
        }
    ]
}

$('.slick-shop-detail-large').slick($optsShopDetailLarge);

//*******************************
// slick slider detail small
//*******************************

var $optsShopDetailSmall = {
    dots: false,
    arrows: false,
    centerMode: false,
    centerPadding: '28px',
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnFocus: false,
    pauseOnHover: false,
    asNavFor: '.slick-shop-detail-large',
    focusOnSelect: true,
    responsive: [
        {
            // xxlarge down
            breakpoint: 1599,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            // xlarge down
            breakpoint: 1440,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            // large down
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            // xmedium down
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            // xsmall down
            breakpoint: 640,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        }
    ]
}

$('.slick-shop-detail-small').slick($optsShopDetailSmall);

//*******************************
// select2 for country on registration form and deliveryaddress form
//*******************************

$(document).ready(function () {
    $('.country-select').select2({
        placeholder: 'Land',
        allowClear: true,
        width: 'resolve' // set style="width: 100%" on the select element
    });

    //$('b[role="presentation"]').hide();
    $('.select2-selection__arrow').append('<i class="fa fa-chevron-down"></i>');
});

$(document).ready(function () {
    $('.pickupLocation-select').select2({
        placeholder: 'Ort',
        allowClear: true,
        //dropdownCssClass: 'test1',
        //selectionCssClass: ':all',
        width: 'resolve' // set style="width: 100%" on the select element
    });

    //$('b[role="presentation"]').hide();
    //$('.select2-selection__arrow').append('<i class="fa fa-chevron-down"></i>');
});

//*******************************
// Shop
//*******************************

require('./shop/shop');
require('./shop/ajax');
require('./shop/forms');

//*******************************
// Website
//*******************************

require('./forms');

import Plyr from 'plyr';

// single
//const player = new Plyr(document.querySelector('.plyr-player'));

// multiple
const players = Plyr.setup('.player');

// autopause other players
if (players) {
    players.forEach(function (player) {
        player.on('play', function () {
            var currentPid = player.id;

            players.forEach(function (player) {
                if (currentPid !== player.id) {
                    player.pause();
                }
            });
        });
    });
}

const teamImage = document.querySelectorAll('.team-section .image-content img');
const close     = document.querySelectorAll('.button-close');

close.forEach((closeBtn) => {
    closeBtn.addEventListener('click', (event) => {

        teamImage.forEach((element) => {
            element.parentElement.classList.remove('show');
            element.classList.remove('active');
        })

    })
});

teamImage.forEach((description) => {
    description.addEventListener('click', (event) => {

        teamImage.forEach((item) => {
            item.parentElement.classList.remove('show');
            item.classList.remove('active');
        })

        description.parentElement.classList.add('show');
        description.classList.add('active');
    })
});


