// required for add to cart - show dropdown
import $ from 'jquery';
window.$ = $;
$(document).foundation();

// element is visible
function isVisible(elem) {
  let elemCoords = elem.getBoundingClientRect();
  let docHeight = document.documentElement.clientHeight;

  return (elemCoords.bottom > 0 && elemCoords.bottom < docHeight);
}

//*******************************
// load more products
//*******************************

// dont run while getting more products
var isLoading = false;

// scroll event
window.addEventListener('scroll', function() {
  var ajaxListing = document.getElementById('ajaxListing');

  if (isLoading === false && ajaxListing && isVisible(ajaxListing)) {
    ajaxLoadMoreProducts();
  }
});

function ajaxLoadMoreProducts() {
  var ajaxLoadMore = document.getElementById('ajaxLoadMore');
  var ajaxItems = document.getElementById('ajaxItems');

  if (ajaxLoadMore && ajaxItems) {
    isLoading = true;

    var url = ajaxLoadMore.dataset.url;

    fetch(url)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.text();
    }).then(function(response) {
      // remove old button
      ajaxLoadMore.remove();

      // add new html
      // ajaxItems.innerHTML += response;
      ajaxItems.insertAdjacentHTML('beforeend', response);

      // move new button
      var newButton = document.getElementById('ajaxLoadMore');
      if (newButton) {
        newButton.remove();
        document.getElementById('ajaxListing').appendChild(newButton);
      }
    }).then(function() {
      ajaxAddToFavourites();
      ajaxRemoveFromFavourites();
      ajaxAddToCart();

      isLoading = false;
    }).catch(function(error) {
      console.log(error);
    });
  }
}

//*******************************
// add to favourites
//*******************************

ajaxAddToFavourites();

function ajaxAddToFavourites() {
  var ajaxAddToFavourites = document.querySelectorAll('.ajax-add-to-favourites');

  for (var i = 0; i < ajaxAddToFavourites.length; i++) {
    ajaxAddToFavourites[i].addEventListener('click', function(e) {
      e.preventDefault();

      var url = this.dataset.url;
      var id = this.dataset.id;

      // show loader icon
      var element = this;
      element.classList.add("loading");

      fetch(url)
      .then(function(response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.text();
      }).then(function() {
        updateFavouritesNavigation();
        updateFavouritesCounter();
        updateFavouritesListing();
        updateFavouritesButton(id);
      }).then(function() {
        // hide loader icon
        element.classList.remove("loading");
      }).catch(function(error) {
        console.log(error);
      });
    })
  }
}

//*******************************
// remove from favourites
//*******************************

ajaxRemoveFromFavourites();

function ajaxRemoveFromFavourites() {
  var ajaxRemoveFromFavourites = document.querySelectorAll('.ajax-remove-from-favourites');

  for (var i = 0; i < ajaxRemoveFromFavourites.length; i++) {
    ajaxRemoveFromFavourites[i].addEventListener('click', function(e) {
      e.preventDefault();

      var url = this.dataset.url;
      var id = this.dataset.id;

      // show loader icon
      var element = this;
      element.classList.add("loading");

      fetch(url)
      .then(function(response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.text();
      }).then(function() {
        updateFavouritesNavigation();
        updateFavouritesCounter();
        updateFavouritesListing();
        updateFavouritesButton(id);

        // hide loader icon
        element.classList.remove("loading");
      }).catch(function(error) {
        console.log(error);
      });
    })
  }
}

//*******************************
// update favourites navigation
//*******************************

function updateFavouritesNavigation() {
  var favouritesDropdown = document.getElementById('favouritesDropdown');
  var favouritesDropdownMobile = document.getElementById('favouritesDropdownMobile');

  if (favouritesDropdown) {
    var navigationUrl = favouritesDropdown.dataset.url;

    fetch(navigationUrl)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.text();
    }).then(function(response) {
      favouritesDropdown.innerHTML = response;
    }).then(function() {
      ajaxRemoveFromFavourites();
      ajaxAddToCart();
    }).catch(function(error) {
      console.log(error);
    });
  }

  if (favouritesDropdownMobile) {
    var navigationUrl = favouritesDropdownMobile.dataset.url;

    fetch(navigationUrl)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.text();
    }).then(function(response) {
      favouritesDropdownMobile.innerHTML = response;
    }).then(function() {
      ajaxRemoveFromFavourites();
      ajaxAddToCart();
    }).catch(function(error) {
      console.log(error);
    });
  }
}

//*******************************
// update favourites counter
//*******************************

function updateFavouritesCounter() {
  var favouritesCounter = document.getElementById('favouritesCounter');
  var favouritesCounterMobile = document.getElementById('favouritesCounterMobile');

  if (favouritesCounter) {
    var counterUrl = favouritesCounter.dataset.url;

    fetch(counterUrl)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.text();
    }).then(function(response) {
      favouritesCounter.innerHTML = response;
    }).catch(function(error) {
      console.log(error);
    });
  }

  if (favouritesCounterMobile) {
    var counterUrl = favouritesCounterMobile.dataset.url;

    fetch(counterUrl)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.text();
    }).then(function(response) {
      favouritesCounterMobile.innerHTML = response;
    }).catch(function(error) {
      console.log(error);
    });
  }
}

//*******************************
// update favourites button
//*******************************

function updateFavouritesButton(id) {
  var favouritesButton = document.getElementById(id);

  if (favouritesButton) {
    var url = favouritesButton.dataset.url;

    fetch(url)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.text();
    }).then(function(response) {
      favouritesButton.innerHTML = response;
    }).then(function() {
      ajaxAddToFavourites();
      ajaxRemoveFromFavourites();
    }).catch(function(error) {
      console.log(error);
    });
  }
}

//*******************************
// update favourites listing
//*******************************

function updateFavouritesListing() {
  var favouritesListing = document.getElementById('ajaxFavouriteItems');

  if (favouritesListing) {
    var url = favouritesListing.dataset.url;

    fetch(url)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.text();
    }).then(function(response) {
      favouritesListing.innerHTML = response;
    }).catch(function(error) {
      console.log(error);
    });
  }
}

//*******************************
// add to cart
//*******************************

ajaxAddToCart();

function ajaxAddToCart() {
  var ajaxAddToCart = document.querySelectorAll('.ajax-add-to-cart');
  var quantity = document.getElementById('quantity');

  for (var i = 0; i < ajaxAddToCart.length; i++) {
    if (ajaxAddToCart[i].dataset.listener !== 'true') {

      ajaxAddToCart[i].addEventListener('click', function(e) {
        e.preventDefault();

        var url = this.dataset.url;
        var id = this.dataset.id;

        // show loader icon
        var element = this;
        element.classList.add("loading");

        if (quantity) {
          url += '&quantity=' + quantity.value;
        }

        fetch(url)
        .then(function(response) {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.text();
        }).then(function() {
          updateCartListing();
          updateCartNavigation();
          updateCartCounter();
          updateFavouritesNavigation();
          updateFavouritesCounter();
          updateFavouritesButton(id);

          // hide loader icon
          element.classList.remove("loading");
        }).catch(function(error) {
          console.log(error);
        });
      });

      ajaxAddToCart[i].dataset.listener = 'true';
    }
  }
}

//*******************************
// remove from cart
//*******************************

ajaxRemoveFromCart();

function ajaxRemoveFromCart() {
  var ajaxRemoveFromCart = document.querySelectorAll('.ajax-remove-from-cart');

  for (var i = 0; i < ajaxRemoveFromCart.length; i++) {
    ajaxRemoveFromCart[i].addEventListener('click', function(e) {
      e.preventDefault();

      var url = this.dataset.url;
      var id = this.dataset.id;

      // show loader icon
      var element = this;
      element.classList.add("loading");
      
      fetch(url)
      .then(function(response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.text();
      }).then(function() {
        updateCartListing();
        updateCartNavigation();
        updateCartCounter();

        // hide loader icon
        element.classList.remove("loading");
      }).catch(function(error) {
        console.log(error);
      });
    })
  }
}

//*******************************
// update cart navigation
//*******************************

function updateCartNavigation() {
  var cartDropdown = document.getElementById('cartDropdown');
  var cartDropdownMobile = document.getElementById('cartDropdownMobile');

  if (cartDropdown) {
    var navigationUrl = cartDropdown.dataset.url;

    fetch(navigationUrl)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.text();
    }).then(function(response) {
      cartDropdown.innerHTML = response;
    }).then(function() {
      ajaxRemoveFromCart();
    }).then(function() {
      $('#cartDropdown').foundation('open');
    }).catch(function(error) {
      console.log(error);
    });
  }

  if (cartDropdownMobile) {
    var navigationUrl = cartDropdownMobile.dataset.url;

    fetch(navigationUrl)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.text();
    }).then(function(response) {
      cartDropdownMobile.innerHTML = response;
    }).then(function() {
      ajaxRemoveFromCart();
    }).catch(function(error) {
      console.log(error);
    });
  }

}

//*******************************
// update cart counter
//*******************************

function updateCartCounter() {
  var cartCounter = document.getElementById('cartCounter');
  var cartCounterMobile = document.getElementById('cartCounterMobile');

  if (cartCounter && cartCounterMobile) {
    var counterUrl = cartCounter.dataset.url;

    fetch(counterUrl)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.text();
    }).then(function(response) {
      cartCounter.innerText = response;
      cartCounterMobile.innerText = response;
    }).catch(function(error) {
      console.log(error);
    });
  }
}

//*******************************
// update cart listing
//*******************************

function updateCartListing() {
  var cartListing = document.getElementById('ajaxCart');

  if (cartListing) {
    var listingUrl = cartListing.dataset.url;

    fetch(listingUrl)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.text();
    }).then(function(response) {
      cartListing.innerHTML = response;
    }).then(function() {
      quantitySetters();
      ajaxRemoveFromCart();
      changeItemQuantities();
      addVoucher();
      removeVoucher();
    }).catch(function(error) {
      console.log(error);
    });
  }
}

//*******************************
// change quantity of individual items in shopping cart
//*******************************

quantitySetters();

function quantitySetters() {
  // get ids of all items
  var items = document.getElementsByClassName("item");

  for (var i = 0; i < items.length; i++) {
    var id = items[i].id;

    changeQuantity(id);
  }
}

// change input with increase/decrease buttons
function changeQuantity(id) {
  var btnIncrease = document.getElementById("btn-increase-" + id);
  var btnDecrease = document.getElementById("btn-decrease-" + id);
  var quantity = document.getElementById("quantity-" + id);

  if (btnIncrease) {
    btnIncrease.addEventListener("click", function(e) {
      e.preventDefault();

      if (quantity.value < 99) {
        quantity.value = parseInt(quantity.value, 10) + 1;
      }
    })
  }

  if (btnDecrease) {
    btnDecrease.addEventListener("click", function(e) {
      e.preventDefault();

      if (quantity.value > 1) {
        quantity.value = parseInt(quantity.value, 10) - 1;
      }
    })
  }
}

//*******************************
// change item quantities in cart
//*******************************

changeItemQuantities();

function changeItemQuantities() {
  var cartForm = document.getElementById('cartForm');
  var updateCart = document.getElementById('updateCart');

  if (updateCart) {
    updateCart.addEventListener('click', function(e) {
      e.preventDefault();

      // show loader icon
      var element = this;
      element.classList.add("loading");

      if (cartForm) {
        var url = cartForm.dataset.url;

        // create form data
        const data = new URLSearchParams();
        for (const pair of new FormData(cartForm)) {
          data.append(pair[0], pair[1]);
        }

        fetch(url, {
          method: 'post',
          body: data,
        })
        .then(function(response) {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.text();
        }).then(function() {
          updateCartListing();
          updateCartCounter();
          updateCartNavigation();
        }).catch(function(error) {
          console.log(error);
        });
      }
    })
  }
}

//*******************************
// add voucher
//*******************************

addVoucher();

function addVoucher() {
  var addVoucher = document.getElementById('addVoucher');
  var voucherCode = document.getElementById('voucherCode');

  if (addVoucher) {
    addVoucher.addEventListener('click', function(e) {
      e.preventDefault();

      if (voucherCode && voucherCode.value) {
        var url = addVoucher.dataset.url + '?voucher-code=' + voucherCode.value;

        // show loader icon
        var element = this;
        element.classList.add("loading");

        fetch(url)
        .then(function(response) {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.text();
        }).then(function() {
          updateCartListing();
        }).then(function() {
          
          // update event listeners
          ajaxRemoveFromCart();

          // hide loader icon
          element.classList.remove("loading");
        }).catch(function(error) {
          console.log(error);
        });
      }
    })
  }
}

//*******************************
// remove voucher
//*******************************

removeVoucher();

function removeVoucher() {
  var ajaxRemoveVoucher = document.querySelectorAll('.ajax-remove-voucher');

  for (var i = 0; i < ajaxRemoveVoucher.length; i++) {
    ajaxRemoveVoucher[i].addEventListener('click', function(e) {
      e.preventDefault();

      var url = this.dataset.url;

      // show loader icon
      var element = this;
      element.classList.add("loading");
      
      fetch(url)
      .then(function(response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.text();
      }).then(function() {
        updateCartListing();

        // hide loader icon
        element.classList.remove("loading");
      }).catch(function(error) {
        console.log(error);
      });
    })
  }
}